/* eslint import/prefer-default-export: "off" */
const UUID_V4_REGEX =
  /[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}/i;
const SLASH_TRIM_REGEX = /^\/+|\/+$/g;
const MULTI_SLASH_REGEX = /\/+/;

export const getTitleFromLocation = (pageLocation) => {
  const { pathname } = new URL(pageLocation);
  const cleanedPathname = pathname
    .replace(UUID_V4_REGEX, "")
    .replace(SLASH_TRIM_REGEX, "")
    .replace(MULTI_SLASH_REGEX, " ");
  return `Kaizan - ${cleanedPathname}`;
};
