import { Controller } from "@hotwired/stimulus";

/**
 * Controller to intercept 404 responses fetched by Turbo
 * Turbo does not do any rendering of 404 responses...
 * This controller will check the status of the response and replace the full
 * page with the content of the response.
 */
export default class extends Controller {
  /* eslint-disable-next-line class-methods-use-this */
  intercept(evt) {
    const { fetchResponse } = evt.detail;
    const { status, url } = evt.detail.fetchResponse.response;

    if (status === 404) {
      evt.preventDefault();
      fetchResponse.responseHTML.then((html) => {
        // Replace the full DOM
        document.open("text/html");
        document.write(html);
        document.close();

        // Update the URL and the history
        // Create fake history to have a better behavior on the back/forward
        // history buttons.
        const oldUrl = window.location.href;

        if (url !== oldUrl) {
          window.history.pushState({}, null, url);
          window.Turbo.navigator.history.replace(new URL(url));
        }
      });
    }
  }
}
