import { Controller } from "@hotwired/stimulus";
import { useDropdown, useStickyFooterMultiselect } from "../../behaviors";
import "../../../scss/components/_table-sticky-footer.scss";

export default class WorkStreamAssignObjectFormController extends Controller {
  static targets = [
    "unassignedObjectCard",
    "formSelectFieldElement",
    "formSelectFieldErrorList",
    "formSelectFieldWrapper",
    "checkboxFieldElement",
    "selectAllCheckboxFieldElement",
    "selectAllCheckboxFieldLabel",
    "submitButton",
    "stickyFooter",
  ];

  static classes = [
    "hidden",
    "checkboxSemiSelected",
    "cardSelected",
    "errorFieldWrapper",
  ];

  static values = {
    labelTemplateString: String,
  };

  connect() {
    // only initialize the dropdown if we have elements in the list
    if (this.hasFormSelectFieldElementTarget) {
      useDropdown(this, this.formSelectFieldElementTarget);
      useStickyFooterMultiselect(this);
    }
  }

  clickOutside(evt) {
    this.openDialog(evt);
  }
}
