import { Controller } from "@hotwired/stimulus";
import { useDropdown, useSidePanelContent, useDialog } from "../../behaviors";

export default class WorkInteractionUpdateFormController extends Controller {
  static targets = [
    "work_streamFieldElement",
    "work_streamHelpText",
    "submitButton",
    "dialogWrapper",
    "dialogOverlay",
  ];

  static classes = ["errorFieldWrapper", "errorHelpText", "hidden"];

  static helpTextHiddenClass = "hidden";

  connect() {
    useDropdown(this, this.work_streamFieldElementTarget);
    useSidePanelContent(this);
    useDialog(this);
  }

  clickOutside(evt) {
    this.openDialog(evt);
  }

  handleSelectValue_changed() {
    this.toggleWorkStreamHelpMessage();
  }

  toggleWorkStreamHelpMessage() {
    if (this.work_streamFieldElementTarget.value) {
      this.work_streamHelpTextTarget.classList.add(
        WorkInteractionUpdateFormController.helpTextHiddenClass,
      );
    } else {
      this.work_streamHelpTextTarget.classList.remove(
        WorkInteractionUpdateFormController.helpTextHiddenClass,
      );
    }
  }

  shouldBypassDialog() {
    const selectedSelectValue = this.work_streamFieldElementTarget.value;
    const originalSelectedValue =
      this.work_streamFieldElementTarget.dataset.initialValue;

    const bypassDialog = selectedSelectValue === originalSelectedValue;
    return bypassDialog;
  }
}
