import { Controller } from "@hotwired/stimulus";

export default class AutoSubmitFormController extends Controller {
  static targets = ["form"];

  connect() {
    this.changeListener = this.onChange.bind(this);
    this.formTarget.addEventListener("change", this.changeListener, false);
  }

  onChange() {
    this.formTarget.requestSubmit();
  }
}
