/* eslint import/prefer-default-export: "off" */
const defaultOptions = {
  addCounter: true,
};

/**
 * Enhance textareas elements in forms
 * - Automatically resizes the area based on the content
 * - Sets a counter for the number of characters if it's limited
 * - handles the input through the `handleInputFor_${fieldName}` method which
 *   can be extended in the controller
 * - Surfaces the number of characters for the field and the maximum
 *   number of characters for the field on the controller through the
 *   `maxLengthFor_${fieldName}` and `numberOfCharactersFor_${fieldName}`
 *   properties
 * - Handles the error state of the field.
 */
export const useTextarea = (
  controller,
  fieldName,
  fieldElementTargetPropName,
  fieldHelpTextTargetPropName,
  fieldWrapperTargetPropName,
  errorFieldWrapperClassPropName,
  errorHelpTextClassPropName,
  options = {},
) => {
  const { addCounter } = { ...defaultOptions, ...options };

  const maxLength =
    controller[fieldElementTargetPropName].getAttribute("maxlength");
  const numberOfCharacters =
    controller[fieldElementTargetPropName].value.length;

  const handleInputForFieldMethodName = `handleInputFor_${fieldName}`;
  const maxLengthForFieldPropName = `maxLengthFor_${fieldName}`;
  const numberOfCharactersForFieldPropName = `numberOfCharactersFor_${fieldName}`;
  const setTextareaForFieldMethodName = `setTextareaFor_${fieldName}`;
  const setCharacterCounterForFieldMethodName = `setCharacterCounterFor_${fieldName}`;

  Object.assign(controller, {
    [maxLengthForFieldPropName]: maxLength,
    [numberOfCharactersForFieldPropName]: numberOfCharacters,

    [setTextareaForFieldMethodName]() {
      // Minimise the textarea
      // Start the character counter
      this[fieldElementTargetPropName].style.height = "auto";
      this[fieldElementTargetPropName].style.height =
        `${this[fieldElementTargetPropName].scrollHeight}px`;

      // Add/remove error/hidden classes
      if (
        this[numberOfCharactersForFieldPropName] >
        this[maxLengthForFieldPropName]
      ) {
        this[fieldWrapperTargetPropName].classList.add(
          this[errorFieldWrapperClassPropName],
        );
        if (addCounter) {
          this[fieldHelpTextTargetPropName].classList.add(
            this[errorHelpTextClassPropName],
          );
        }
      } else if (this[numberOfCharactersForFieldPropName] === 0) {
        this[fieldWrapperTargetPropName].classList.remove(
          this[errorFieldWrapperClassPropName],
        );
        if (addCounter) {
          this[fieldHelpTextTargetPropName].classList.remove(
            this[errorHelpTextClassPropName],
          );
        }
      } else {
        this[fieldWrapperTargetPropName].classList.remove(
          this[errorFieldWrapperClassPropName],
        );
        if (addCounter) {
          this[fieldHelpTextTargetPropName].classList.remove(
            this[errorHelpTextClassPropName],
          );
        }
      }
    },

    [setCharacterCounterForFieldMethodName]() {
      if (this[maxLengthForFieldPropName] === null) {
        return;
      }

      // Compute the text to display
      const counterText = `${this[numberOfCharactersForFieldPropName]}/${this[maxLengthForFieldPropName]}`;
      this[fieldHelpTextTargetPropName].innerText = counterText;
    },

    [`${handleInputForFieldMethodName}_fromBehavior`]() {
      this[numberOfCharactersForFieldPropName] =
        this[fieldElementTargetPropName].value.length;
      this[setTextareaForFieldMethodName]();

      if (addCounter) {
        // update the character counter
        this[setCharacterCounterForFieldMethodName]();
      }
    },
  });

  // Mix the controller input handler and the basic text area one.
  const controllerHandleInput =
    controller[handleInputForFieldMethodName]?.bind(controller);

  Object.assign(controller, {
    [handleInputForFieldMethodName]() {
      this[`${handleInputForFieldMethodName}_fromBehavior`]();
      if (controllerHandleInput) {
        controllerHandleInput();
      }
    },
  });

  controller[setTextareaForFieldMethodName]();

  if (addCounter) {
    controller[setCharacterCounterForFieldMethodName]();
  }
};
