import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  /* eslint-disable class-methods-use-this */
  onClick() {
    // Closes the sidebar when user clicks outside the sidebar, thus clicking on the overlay
    // The vertical-overlay is the overlay that appears when the sidebar is open
    document.body.classList.remove("vertical-sidebar-enable");
    document.querySelector(".hamburger-icon").classList.remove("open");
  }
  /* eslint-enable class-methods-use-this */
}
