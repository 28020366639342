import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["messagesList", "template", "templateContent"];

  // eslint-disable-next-line class-methods-use-this
  removeMessage(evt) {
    // Small hack to prevent the message flashing when using Turbo drive for
    // navigation and Turbo stream. The problem was:
    // - The message gets sent as a stream
    // - The dom gets updated, the message show and then disappears when the
    // CSS animation ends but is still in the DOM
    // - The user navigates away (Turbo caches the last version of the page
    // when the navigation starts)
    // - The user navigates back on this pages
    // - Turbo show the cached version (that contains the message that flashes)
    // - Turbo fetches the new version of the pages and replace the DOM (no
    // message so it disappears)
    // Here, by removing the message from the DOM we prevent to be cached and
    // to show again.
    evt.target.remove();
  }

  flash({ detail: { content, status } }) {
    let messageClass = status;
    if (status === "error") {
      messageClass = "alert-danger";
    } else if (status === "warning") {
      messageClass = "alert-warning";
    } else if (status === "info") {
      messageClass = "alert-info";
    } else if (status === "success") {
      messageClass = "alert-success";
    }
    const template = this.templateTarget;
    const clone = template.content.cloneNode(true);

    clone.firstElementChild.classList.add("alert");
    clone.firstElementChild.classList.add(messageClass);
    clone.firstElementChild.innerText = content;
    this.messagesListTarget.insertBefore(
      clone,
      this.messagesListTarget.firstElementChild,
    );
  }
}

export function flashMessage(application, content, status) {
  const messagesController = application.getControllerForElementAndIdentifier(
    document.getElementById("messages"),
    "messages",
  );
  messagesController.flash({
    detail: {
      content,
      status,
    },
  });
}
