import { Controller } from "@hotwired/stimulus";
import { updateWindowLocationSearch } from "../../behaviors";

export default class extends Controller {
  static values = {
    urlEncodedQueryString: String,
  };

  connect() {
    updateWindowLocationSearch(this);
  }
}
