import { Controller } from "@hotwired/stimulus";
import "choices.js/src/styles/choices.scss";

export default class extends Controller {
  static targets = ["clientSearch", "clientList"];

  static values = {
    url: String,
  };

  onChange() {
    const selectedOption = Array.from(this.clientListTarget.options).find(
      (option) => option.value === this.clientSearchTarget.value,
    );
    if (selectedOption) {
      const { url } = selectedOption.dataset;
      window.location.href = url;
    }
  }
}
