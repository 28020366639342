import $ from "jquery";
import { Controller } from "@hotwired/stimulus";
import Choices from "choices.js";
import "choices.js/src/styles/choices.scss";

export default class WorkStreamFormUpdateController extends Controller {
  static targets = ["form", "usersSelectField"];

  connect() {
    if (this.hasUsersSelectFieldTarget) {
      /* eslint-disable-next-line no-new */
      new Choices(this.usersSelectFieldTarget, {
        allowHTML: true,
        removeItemButton: true,
        placeholder: true,
      });
    }

    this.changeListener = this.onChange.bind(this);
    this.formTarget.addEventListener("change", this.changeListener, false);
    this.formTarget.addEventListener(
      "turbo:submit-end",
      this.submitEnd.bind(this),
    );
  }

  onChange(e) {
    // reload the stakeholders card if the csat_enabled or domain_guidelines fields are changed
    // in order to show the correct toggles as they might have changed
    this.reloadStakeholders = false;
    if (
      e.target.name === "csat_enabled" ||
      e.target.name === "domain_guidelines"
    ) {
      this.reloadStakeholders = true;
    }
  }

  onButtonClick() {
    // Show a custom error message if no owner is available for the work stream
    const searchUsersInput = $("input[type='search'][name='search_terms']")[0];
    if (this.usersSelectFieldTarget.value === "") {
      searchUsersInput.setCustomValidity("Please select at least one owner.");
      searchUsersInput.reportValidity();
    } else {
      searchUsersInput.setCustomValidity("");
      this.formTarget.requestSubmit();
    }
  }

  submitEnd(e) {
    if (e.detail.success) {
      if (this.reloadStakeholders) {
        // reload the stakehodlers tab if we're on it
        // find the nav-link with data attribute data-tab-name="stakeholders"
        // and trigger a click on it if it has the 'active' class
        const stakeholdersTab = document.querySelector(
          "a[data-tab-name='stakeholders']",
        );
        if (stakeholdersTab) {
          if (stakeholdersTab.classList.contains("active")) {
            stakeholdersTab.click();
          }
        }
      }
      // close the modal dialog on success
      $(this.formTarget).closest(".modal").modal("hide");
    }
  }
}
