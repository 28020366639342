import { Controller } from "@hotwired/stimulus";

/**
 * Controller to be used on a link element that specify an internal redirect
 * that should be updated when the tab navigation is triggered.
 */
export default class extends Controller {
  static values = {
    redirectFieldName: { type: String, default: "next" },
  };

  static targets = ["link"];

  updateHref(evt) {
    const {
      detail: { targetUrl: targetUrlStr },
    } = evt;
    if (targetUrlStr) {
      let targetUrl = targetUrlStr;
      try {
        targetUrl = new URL(targetUrlStr);
      } catch (e) {} // eslint-disable-line no-empty

      const { pathname = targetUrl } = targetUrl;
      this.linkTargets.forEach((el) => {
        const url = new URL(el.href);
        url.searchParams.set(this.redirectFieldNameValue, pathname);
        el.href = url; // eslint-disable-line no-param-reassign
      });
    }
  }
}
