import { Controller } from "@hotwired/stimulus";

export default class WorkInteractionDetailEmailController extends Controller {
  // eslint-disable-next-line class-methods-use-this
  toggleThreadReplies(evt) {
    const { threadRepliesBoxId } = evt.target.dataset;
    const threadRepliesBox = document.querySelector(`#${threadRepliesBoxId}`);

    if (
      threadRepliesBox.style.display === "none" ||
      threadRepliesBox.style.display === ""
    ) {
      threadRepliesBox.style.display = "block";
    } else {
      threadRepliesBox.style.display = "none";
    }
  }
}
