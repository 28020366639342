/*
 * This software or document includes material copied from or derived from
 * https://www.w3.org/TR/wai-aria-practices-1.1/examples/listbox/listbox-collapsible.html.
 * Copyright © 2022 W3C® (MIT, ERCIM, Keio, Beihang).
 */

import { Controller } from "@hotwired/stimulus";
import KeyValues from "../../utils/key_values";

export default class ListboxController extends Controller {
  static classes = [];

  static targets = ["listbox"];

  static focusedClass = "focused";

  /**
   * @desc
   *  Listbox object representing the state and interactions for a listbox widget
   */
  connect() {
    this.multiselectable = this.listboxTarget.hasAttribute(
      "aria-multiselectable",
    );
    this.keysSoFar = "";

    const activeDescendantValue = this.listboxTarget.getAttribute(
      "aria-activedescendant-value",
    );

    this.activeDescendant = this.listboxTarget.querySelector(
      `[data-value="${activeDescendantValue}"]`,
    );
    if (!this.activeDescendant) {
      const firstItem = this.listboxTarget.querySelector('[role="option"]');
      this.activeDescendant = firstItem;
    }
    this.setupSelection();
    this.registerEvents();
  }

  /**
   * @desc
   *  Register events for the listbox interactions
   */
  registerEvents() {
    this.listboxTarget.addEventListener("focus", this.setupFocus.bind(this));
    this.listboxTarget.addEventListener(
      "keydown",
      this.checkKeyPress.bind(this),
    );
    this.listboxTarget.addEventListener(
      "click",
      this.checkClickItem.bind(this),
    );
    Array.from(this.listboxTarget.children).forEach((el) => {
      el.addEventListener("mouseenter", (evt) => {
        this.focusItem(evt.target);
      });
      el.addEventListener("mouseleave", (evt) => {
        this.defocusItem(evt.target);
      });
    });
  }

  setupSelection() {
    this.toggleSelectItem(this.activeDescendant);
  }

  /**
   * @desc
   *  If there is no activeDescendant, focus on the first option
   */
  setupFocus() {
    if (this.activeDescendant) {
      return;
    }

    this.focusFirstItem();
  }

  /**
   * @desc
   *  Focus on the first option
   */
  focusFirstItem() {
    const firstItem = this.listboxTarget.querySelector('[role="option"]');

    if (firstItem) {
      this.focusItem(firstItem);
    }
  }

  /**
   * @desc
   *  Focus on the last option
   */
  focusLastItem() {
    const itemList = this.listboxTarget.querySelectorAll('[role="option"]');

    if (itemList.length) {
      this.focusItem(itemList[itemList.length - 1]);
    }
  }

  /**
   * @desc
   *  Handle various keyboard controls; UP/DOWN will shift focus; SPACE selects
   *  an item.
   *
   * @param evt
   *  The keydown event object
   */
  checkKeyPress(evt) {
    const { key } = evt;
    let nextItem = this.activeDescendant;

    if (!nextItem) {
      return;
    }

    switch (key) {
      case KeyValues.UP:
      case KeyValues.ARROW_UP:
      case KeyValues.ARROW_DOWN:
      case KeyValues.DOWN:
        evt.preventDefault();

        if (key === KeyValues.UP || key === KeyValues.ARROW_UP) {
          nextItem = nextItem.previousElementSibling;
        } else {
          nextItem = nextItem.nextElementSibling;
        }

        if (nextItem) {
          this.focusItem(nextItem);
        }

        break;
      case KeyValues.HOME:
        evt.preventDefault();
        this.focusFirstItem();
        break;
      case KeyValues.END:
        evt.preventDefault();
        this.focusLastItem();
        break;
      case KeyValues.SPACE:
      case KeyValues.ENTER:
        evt.preventDefault();
        this.toggleSelectItem(nextItem);
        break;

      default:
        // eslint-disable-next-line no-case-declarations
        const itemToFocus = this.findItemToFocus(key);
        if (itemToFocus) {
          this.focusItem(itemToFocus);
        }
        break;
    }
  }

  findItemToFocus(key) {
    // Assumes that the list of items can change over time and recreates the
    // sorted option list every time.
    if (key.length > 1) {
      return null;
    }
    const itemList = this.listboxTarget.querySelectorAll('[role="option"]');

    const sortedOptionsWithLowerCaseValues = Array.from(itemList)
      .map((el) => ({ el, name: el.innerText.toLowerCase().trim() }))
      .sort((a, b) => (a.name > b.name ? 1 : -1));

    this.keysSoFar += key;
    this.clearKeysSoFarAfterDelay();

    const bestMatch = this.findMatchInRange(sortedOptionsWithLowerCaseValues);
    return bestMatch.el;
  }

  clearKeysSoFarAfterDelay() {
    if (this.keyClear) {
      clearTimeout(this.keyClear);
      this.keyClear = null;
    }
    this.keyClear = setTimeout(() => {
      this.keysSoFar = "";
      this.keyClear = null;
    }, 500);
  }

  findMatchInRange(list) {
    // Find the first item starting with the keysSoFar substring, searching in
    // the specified range of items
    let lastItem;
    for (let i = 0; i < list.length; i += 1) {
      const { name } = list[i];
      const stopSearch =
        name.indexOf(this.keysSoFar) !== 0 && this.keysSoFar < name;
      if (stopSearch) {
        break;
      }
      lastItem = list[i];
    }
    return lastItem || list[0];
  }

  /**
   * @desc
   *  Check if an item is clicked on. If so, focus on it and select it.
   *
   * @param evt
   *  The click event object
   */
  checkClickItem(evt) {
    if (evt.target.getAttribute("role") === "option") {
      this.focusItem(evt.target);
      this.toggleSelectItem(evt.target);
    }
  }

  /**
   * @desc
   *  Toggle the aria-selected value
   *
   * @param element
   *  The element to select
   */
  toggleSelectItem(element) {
    if (!this.multiselectable) {
      this.listboxTarget
        .querySelectorAll('[aria-selected="true"]')
        .forEach((el) => el.removeAttribute("aria-selected"));
    }
    element.setAttribute(
      "aria-selected",
      element.getAttribute("aria-selected") === "true" ? "false" : "true",
    );

    this.dispatch("itemSelected", { detail: { element } });
  }

  /**
   * @desc
   *  Defocus the specified item
   *
   * @param element
   *  The element to defocus
   */
  /* eslint-disable-next-line class-methods-use-this */
  defocusItem(element) {
    if (!element) {
      return;
    }
    element.classList.remove(ListboxController.focusedClass);
  }

  /**
   * @desc
   *  Focus on the specified item
   *
   * @param element
   *  The element to focus
   */
  focusItem(element) {
    this.defocusItem(this.activeDescendant);
    element.classList.add(ListboxController.focusedClass);
    this.listboxTarget.setAttribute("aria-activedescendant", element.id);
    this.activeDescendant = element;

    if (this.listboxTarget.scrollHeight > this.listboxTarget.clientHeight) {
      const scrollBottom =
        this.listboxTarget.clientHeight + this.listboxTarget.scrollTop;
      const elementBottom = element.offsetTop + element.offsetHeight;
      if (elementBottom > scrollBottom) {
        this.listboxTarget.scrollTop =
          elementBottom - this.listboxTarget.clientHeight;
      } else if (element.offsetTop < this.listboxTarget.scrollTop) {
        this.listboxTarget.scrollTop = element.offsetTop;
      }
    }

    this.dispatch("itemFocused", { detail: { element } });
  }
}
