import { Controller } from "@hotwired/stimulus";
import { trackEventWithDataToAmplitudeAndGoogle } from "../../analytics/global_analytics";

export default class extends Controller {
  static targets = ["searchInput"];

  handleSearch() {
    const searchQuery = this.searchInputTarget.value;

    trackEventWithDataToAmplitudeAndGoogle(
      "userSearch",
      "search",
      "Search - User Search",
      {
        query: searchQuery,
      },
    );
  }
}
