import { Controller } from "@hotwired/stimulus";
import InlineEditor from "../../components/inline_editor";

export default class EmailEditorController extends Controller {
  static targets = ["inlineEditor"];

  isDirty = false;

  connect() {
    this.editor = InlineEditor(this.inlineEditorTarget);
    const scopedThis = this; // As you can't access this from the function below
    this.editor.on("text-change", () => {
      scopedThis.isDirty = true;
    });
  }

  copyContentToClipboard() {
    const content = this.editor.getText();
    const htmlContent = this.editor.root.innerHTML;
    const clipboardItem = new ClipboardItem({
      "text/html": new Blob([htmlContent], { type: "text/html" }),
      "text/plain": new Blob([content], { type: "text/plain" }),
    });
    navigator.clipboard.write([clipboardItem]).then(
      () => {},
      (error) => console.error(error), // eslint-disable-line no-console
    );
  }
}
