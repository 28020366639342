import { Controller } from "@hotwired/stimulus";

export default class WorkInteractionListRecent extends Controller {
  active(event) {
    this.element.querySelectorAll(".btn").forEach((btn) => {
      btn.classList.remove("active");
    });

    event.currentTarget.classList.add("active");
  }
}
