/* eslint import/prefer-default-export: "off" */
import { extendedEvent } from "./behaviors";

/**
 * Breakpoints
 * Defines all the breakpoints that can be used on the website. They need to
 * match the ones defined in CSS in `assets/css/core/responsive.css`.
 * The CSS defined breakpoints will be imported in JS and we will detect
 * breakpoint changes when the window gets resized.
 * Based on:
 * https://www.lullabot.com/articles/importing-css-breakpoints-into-javascript
 */
export const Breakpoint = {
  MOBILE: "mobile",
  TABLET_PORTRAIT: "tablet-portrait",
  DESKTOP: "desktop",
  DESKTOP_LARGE: "desktop-large",
};

export const breakpoints = { old: null, new: null };

export const refreshBreakpointValue = () => {
  breakpoints.new = window
    .getComputedStyle(document.querySelector("body"), ":before")
    .getPropertyValue("content")
    .replace(/"/g, "");
};

// Tries to get a first value of the breakpoint (may fail if the CSS have not
// been fully applied yet)
refreshBreakpointValue();

// Attach a global listener to track when the breakpoint changes
const breakpointResizeListener = (evt) => {
  const currentBreakpointValue = breakpoints.new;
  refreshBreakpointValue();
  if (breakpoints.new !== currentBreakpointValue) {
    breakpoints.old = currentBreakpointValue;
    const event = extendedEvent("breakpointchange", evt, breakpoints);

    window.dispatchEvent(event);
  }
};

window.addEventListener("resize", breakpointResizeListener);
