import { Controller } from "@hotwired/stimulus";
import { useInput } from "../../behaviors";

export default class extends Controller {
  static targets = [
    "contentFieldElement",
    "contentHelpText",
    "contentFieldWrapper",
  ];

  static classes = ["errorFieldWrapper", "errorHelpText", "textareaValidEmpty"];

  connect() {
    useInput(
      this,
      "content",
      "contentFieldElementTarget",
      "contentHelpTextTarget",
      "contentFieldWrapperTarget",
      "errorFieldWrapperClass",
      "errorHelpTextClass",
    );

    this.contentFieldElementTarget.required = false;
  }
}
