import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import { trackEventWithDataToAmplitudeAndGoogle } from "../../analytics/global_analytics";
import { useStreamedResponse } from "../../behaviors";
import { StreamingResponseEventType } from "../../behaviors/use_streamed_response";
import { flashMessage } from "../messages_controller";

export default class extends Controller {
  static targets = [
    "generateButtons",
    "textQuestion",
    "sendButton",
    "thinking",
    "form",
    "questionAndAnswerContainer",
    "questionContainer",
    "answerContainer",
  ];

  connect() {
    useStreamedResponse(this);
  }

  handleStreamingResponseEvent(eventType, eventData) {
    if (eventType === StreamingResponseEventType.SUBMIT_START) {
      this.handleSubmitStart();
    } else if (eventType === StreamingResponseEventType.SUBMIT_END) {
      this.handleSubmitEnd();
    } else if (eventType === StreamingResponseEventType.RESPONSE_RECEIVED) {
      this.answerContainerTarget.innerHTML = "";
    } else if (
      eventType === StreamingResponseEventType.PARTIAL_RESPONSE_RECEIVED
    ) {
      this.handlePartialResponseReceived(eventData);
    } else if (eventType === StreamingResponseEventType.STREAMING_PROCESS_END) {
      this.textQuestionTarget.value = "";
    }
  }

  handleSubmitStart() {
    const question = this.textQuestionTarget.value;
    this.thinkingTarget.classList.remove("d-none");
    this.questionAndAnswerContainerTarget.classList.remove("d-none");
    this.questionContainerTarget.innerHTML = question;
    this.answerContainerTarget.innerHTML = "Thinking...";
  }

  handleSubmitEnd() {
    const question = this.questionContainerTarget.innerHTML;
    this.thinkingTarget.classList.add("d-none");
    // check if the response value exists and submit the form again
    const formUrl = this.formTarget.getAttribute("action");
    const responseValue = this.getHiddenResponseField().val();
    if (responseValue) {
      fetch(formUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-CSRFToken": $(this.formTarget)
            .find("input[name=csrfmiddlewaretoken]")
            .val(),
        },
        body:
          `question=${encodeURIComponent(question)}` +
          `&response=${encodeURIComponent(responseValue)}`,
      })
        .then(() => {
          const frame = document.getElementById("card-content-chatbot");
          frame.src = frame.src; // eslint-disable-line no-self-assign
        })
        .catch((error) => {
          /* eslint-disable-next-line no-console */
          console.error(
            "There has been a problem sending the chatbot question and response:",
            error,
          );
          flashMessage(
            this.application,
            "Error saving chatbot response. Please contact Kaizan if the error persists.",
            "error",
          );
        })
        .finally(() => {});
    }
  }

  getHiddenResponseField() {
    return $(this.formTarget).find("textarea[name=response]");
  }

  handlePartialResponseReceived(eventData) {
    let conversation = this.answerContainerTarget.innerHTML;
    const value = eventData;
    const decoder = new TextDecoder();
    const decodedValue = decoder.decode(value);
    conversation += decodedValue;

    // Append to the response field to send it back to the server
    // Get the response value already set and append to it the current decoded value
    const responseValue = this.getHiddenResponseField().val();
    this.getHiddenResponseField().val(responseValue + decodedValue);

    conversation = conversation.replace(/(?:\r\n|\r|\n)/g, "<br>");
    this.answerContainerTarget.innerHTML = conversation;
  }

  getQueryString() {
    const question = this.textQuestionTarget.value;
    const queryString = `question=${encodeURIComponent(question)}`;
    return queryString;
  }

  send() {
    this.generateButtonsTarget.classList.add("d-none");
    trackEventWithDataToAmplitudeAndGoogle(
      "Question",
      "chatbot",
      "Chatbot - Question",
      {
        query: this.textQuestionTarget.value,
      },
    );
  }

  copy(event) {
    event.preventDefault();
    trackEventWithDataToAmplitudeAndGoogle(
      "Copy",
      "chatbot",
      "Chatbot - Copy",
      {
        query: this.textQuestionTarget.value,
      },
    );
  }

  share() {
    trackEventWithDataToAmplitudeAndGoogle(
      "Share",
      "chatbot",
      "Chatbot - Share",
      {
        query: this.textQuestionTarget.value,
      },
    );

    // open share modal
    $("#shareWith").modal("show");
  }

  generateFollowupEmail() {
    this.textQuestionTarget.value = "Write a follow-up email";
    this.sendButtonTarget.click();

    trackEventWithDataToAmplitudeAndGoogle(
      "Write a follow-up email",
      "chatbot",
      "Chatbot - Write a follow-up email",
      {
        query: "Write a follow-up email",
      },
    );
  }

  generateAgenda() {
    this.textQuestionTarget.value = "Write an agenda for the next meeting";
    this.sendButtonTarget.click();

    trackEventWithDataToAmplitudeAndGoogle(
      "Write an agenda",
      "chatbot",
      "Chatbot - Write an agenda",
      {
        query: "Write an agenda",
      },
    );
  }

  generateRecommendedAnswers() {
    this.textQuestionTarget.value = "Generate recommended answers";
    this.sendButtonTarget.click();

    trackEventWithDataToAmplitudeAndGoogle(
      "Generate recommended answers",
      "chatbot",
      "Chatbot - Recommended answers",
      {
        query: "Recommended answers",
      },
    );
  }
}
