import { Controller } from "@hotwired/stimulus";
import InlineEditor from "../../components/inline_editor";

import { request } from "../../utils/http/client";
import { HTTP_METHODS } from "../../utils/http/constants";

export default class InlineEditorController extends Controller {
  static targets = ["inlineEditor"];

  static values = {
    postUrl: String, // Url to post to
    postField: String, // field to send data to
  };

  isDirty = false;

  connect() {
    this.editor = InlineEditor(this.inlineEditorTarget);
    const scopedThis = this; // As you can't access this from the function below
    this.editor.on("text-change", () => {
      scopedThis.isDirty = true;
    });
    this.doAutoSave(5);
  }

  doAutoSave(seconds) {
    // Auto saves the content of the editor every x seconds if content has changed
    setInterval(async () => {
      if (!this.isDirty) {
        return;
      }
      try {
        const data = {};
        data[this.postFieldValue] =
          this.inlineEditorTarget.getElementsByClassName(
            "ql-editor",
          )[0].innerHTML;
        await request(HTTP_METHODS.PATCH, this.postUrlValue, data);
        this.isDirty = false;
      } catch (error) {
        console.error(error); // eslint-disable-line no-console
      }
    }, seconds * 1000);
  }
}
