/* eslint import/prefer-default-export: "off" */
import { trackEventWithDataToAmplitudeAndGoogle } from "../../analytics/global_analytics";

/**
 * Allow a controller to use email analytics events.
 */
export const useEmailAnalytics = (controller) => {
  Object.assign(controller, {
    trackEmailClick(evt) {
      const { target } = evt;
      const eventName =
        target.dataset.analyticsEventName || "unknownEmailElementClick";
      const eventLabel =
        target.dataset.analyticsEventLabel || "Unknown email element clicked";

      trackEventWithDataToAmplitudeAndGoogle(eventName, "email", eventLabel);
    },
  });
};
