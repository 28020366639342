/* eslint import/prefer-default-export: "off" */
import camelcaseKeys from "camelcase-keys";

import { HTTP_METHODS } from "../utils/http/constants";
import { request } from "../utils/http/client";

const ENDPOINT = "/api/v2/me";

export const patchUser = async (data) => {
  const user = await request(HTTP_METHODS.PATCH, ENDPOINT, data);
  return camelcaseKeys(user);
};
