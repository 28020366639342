import { Controller } from "@hotwired/stimulus";
import { request } from "../../utils/http/client";
import { HTTP_METHODS } from "../../utils/http/constants";

export default class extends Controller {
  static targets = ["fieldValue"];

  static values = {
    postUrl: String,
    fieldName: String,
  };

  async onChanged() {
    const POST_DATA = {};
    POST_DATA[this.fieldNameValue] = this.fieldValueTarget.value;

    if (
      this.fieldValueTarget.type &&
      this.fieldValueTarget.type === "checkbox"
    ) {
      POST_DATA[this.fieldNameValue] = this.fieldValueTarget.checked;
    }

    try {
      await request(HTTP_METHODS.PATCH, this.postUrlValue, POST_DATA);
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
    }
  }
}
