/* eslint import/prefer-default-export: "off" */
import { breakpoints } from "../utils/responsive";

/**
 * Allows a controller to know when a breakpoint value is changing.
 * - The controller must define the `breakpointChange` method
 */
export const useResponsive = (controller) => {
  const breakpointChangeEventListener = (evt) => {
    controller.breakpointChange(evt.detail);
  };

  window.addEventListener(
    "breakpointchange",
    breakpointChangeEventListener,
    false,
  );

  // keep a copy of the current disconnect() function of the controller
  // to support composing several behaviors
  const controllerDisconnect = controller.disconnect.bind(controller);

  Object.assign(controller, {
    disconnect() {
      window.removeEventListener(
        "breakpointchange",
        breakpointChangeEventListener,
        false,
      );
      controllerDisconnect();
    },
  });

  controller.breakpointChange(breakpoints);
};
