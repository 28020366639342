import Quill from "quill";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

const InlineEditor = (targetId) => {
  const editor = new Quill(targetId, {
    placeholder: "Add your notes here ...",
    theme: "snow",
  });
  return editor;
};

export default InlineEditor;
