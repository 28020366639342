import { Controller } from "@hotwired/stimulus";
import { useSidePanelContent } from "../../behaviors";

export default class extends Controller {
  connect() {
    useSidePanelContent(this);
  }

  clickOutside() {
    this.dispatch("click:outside");
  }
}
