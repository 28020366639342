/*
Template Name: Velzon - Admin & Dashboard Template
Author: Themesbrand
Version: 3.0.0
Website: https://Themesbrand.com/
Contact: Themesbrand@gmail.com
File: Main Js File
*/

import { Popover, Tooltip } from "bootstrap";

/* eslint-disable-next-line func-names */
(function () {
  /**
   *  global variables
   */

  function initActiveMenu() {
    const currentPath =
      window.location.pathname === "/"
        ? "/"
        : `/${window.location.pathname.substring(1)}`;

    if (currentPath) {
      // navbar-nav
      const a = document
        .getElementById("navbar-nav")
        ?.querySelector(`[href="${currentPath}"]`);

      if (a) {
        a.classList.add("active");
        const parentCollapseDiv = a.closest(".collapse.menu-dropdown");
        if (parentCollapseDiv) {
          parentCollapseDiv.classList.add("show");
          parentCollapseDiv.parentElement.children[0].classList.add("active");
          parentCollapseDiv.parentElement.children[0].setAttribute(
            "aria-expanded",
            "true",
          );
          if (
            parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")
          ) {
            parentCollapseDiv.parentElement
              .closest(".collapse")
              .classList.add("show");
            if (
              parentCollapseDiv.parentElement.closest(".collapse")
                .previousElementSibling
            )
              parentCollapseDiv.parentElement
                .closest(".collapse")
                .previousElementSibling.classList.add("active");
            if (
              parentCollapseDiv.parentElement.parentElement.parentElement.parentElement.closest(
                ".collapse.menu-dropdown",
              )
            ) {
              parentCollapseDiv.parentElement.parentElement.parentElement.parentElement
                .closest(".collapse")
                .classList.add("show");
              if (
                parentCollapseDiv.parentElement.parentElement.parentElement.parentElement.closest(
                  ".collapse",
                ).previousElementSibling
              ) {
                parentCollapseDiv.parentElement.parentElement.parentElement.parentElement
                  .closest(".collapse")
                  .previousElementSibling.classList.add("active");
                if (
                  document.documentElement.getAttribute("data-layout") ===
                    "horizontal" &&
                  parentCollapseDiv.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.closest(
                    ".collapse",
                  )
                ) {
                  parentCollapseDiv.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement
                    .closest(".collapse")
                    .previousElementSibling.classList.add("active");
                }
              }
            }
          }
        }
      }
    }
  }

  function initComponents() {
    // tooltip
    const tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-toggle="tooltip"]'),
    );
    tooltipTriggerList.map((tooltipTriggerEl) => new Tooltip(tooltipTriggerEl));

    // popover
    const popoverTriggerList = [].slice.call(
      document.querySelectorAll('[data-toggle="popover"]'),
    );
    popoverTriggerList.map((popoverTriggerEl) => new Popover(popoverTriggerEl));
  }

  function domObserver() {
    if (!document.getElementById("navbar-nav")) {
      return;
    }

    // Options for the observer (which mutations to observe)
    const config = { attributes: false, childList: true, subtree: true };

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(initActiveMenu);

    // Start observing the target node for configured mutations
    observer.observe(document.getElementById("navbar-nav"), config);
  }

  function init() {
    initComponents();
    initActiveMenu();
    domObserver();
  }
  init();
  // Turbo frame navigation replaces DOM.
  // Our event listeners need to be re-initialised
  document.addEventListener("turbo:frame-load", () => {
    init();
  });
})();
