/* eslint class-methods-use-this: ["error", { "exceptMethods": ["searchResultClick"] }] */
import { Controller } from "@hotwired/stimulus";
import { trackEventWithDataToAmplitudeAndGoogle } from "../../analytics/global_analytics";

export default class extends Controller {
  static targets = ["searchResultsContainer", "searchResult"];

  connect() {
    if (this.hasSearchResultsContainerTarget) {
      this.trackSearchResults();
    }
  }

  trackSearchResults() {
    const { searchQuery } = this.searchResultsContainerTarget.dataset;
    const searchResults = [];

    for (let i = 0; i < this.searchResultTargets.length; i += 1) {
      const searchResultTarget = this.searchResultTargets[i];
      const searchPosition = searchResultTarget.dataset.position;
      const entryId = searchResultTarget.dataset;

      searchResults.push({
        position: searchPosition,
        entry_id: entryId,
      });
    }

    trackEventWithDataToAmplitudeAndGoogle(
      "userSearchResults",
      "search",
      "Search - User Search Results",
      {
        search_query: searchQuery,
        results_count: this.searchResultTargets.length,
        results: searchResults,
      },
    );
  }

  searchResultClick(evt) {
    const cardCell = evt.target.closest(".card-cell");
    const searchPosition = cardCell.dataset.position;
    const { entryId } = cardCell.dataset;

    trackEventWithDataToAmplitudeAndGoogle(
      "searchResultClick",
      "search",
      "Search - User Search Result Click",
      {
        position: searchPosition,
        entry_id: entryId,
      },
    );
  }
}
