/* eslint import/prefer-default-export: "off", max-classes-per-file: "off" */
import camelCase from "camelcase";

import { CustomError } from "../errors";
import { STATUS_CODES } from "./constants";

/**
 * Converts an HTTP status code to an Error `name`.
 * Ex:
 *   302 => "Found"
 *   404 => "NotFoundError"
 *   500 => "InternalServerError"
 */

export function statusCodeToName(code) {
  return camelCase(STATUS_CODES[code], { pascalCase: true });
}

export class HTTPError extends CustomError {
  constructor(code, response, message) {
    super(statusCodeToName(code));
    this.message = message;
    this.response = response;
    this.statusCode = code;
  }
}

/**
 * A few common instances.
 */
export class UnauthorizedError extends HTTPError {}

export class UnknownHTTPError extends HTTPError {}
