import { Controller } from "@hotwired/stimulus";

const rater = require("rater-js");

export default class CSATUpdateController extends Controller {
  static targets = ["score", "rater"];

  connect() {
    this.readonly = this.raterTarget.dataset.readonly === "true";
    this.initRater();
  }

  initRater() {
    const { scoreTarget } = this;
    const { readonly } = this;
    const scoreRater = rater({
      element: this.raterTarget,
      max: 10,
      starSize: 32,
      rateCallback: function rateCallback(rating, done) {
        if (readonly === false) {
          scoreTarget.value = rating;
          scoreRater.setRating(rating);
        }
        done();
      },
    });
    if (readonly === true) {
      scoreRater.disable();
    }
    if (scoreTarget.value > 0) {
      scoreRater.setRating(Number(scoreTarget.value));
    }
  }
}
