/* eslint import/prefer-default-export: "off" */

const replaceInput = (inputTarget, options) => {
  const div = document.createElement("div");
  div.dataset.controller = "utils--datetime-picker";
  div.dataset["utils-DatetimePickerPlaceholderValue"] = inputTarget.placeholder;
  div.dataset["utils-DatetimePickerInitialValue"] = inputTarget.value;
  div.dataset["utils-DatetimePickerMinValue"] = inputTarget.min;
  div.dataset["utils-DatetimePickerDatetimePickerRootElementSelectorValue"] =
    options.rootElementSelector;
  div.dataset["utils-DatetimePickerMaxValue"] = inputTarget.max;
  div.dataset["utils-DatetimePickerDisableTimeSectionValue"] =
    options.disableTimeSection;

  div.setAttribute("class", "datetime-picker");

  const button = document.createElement("button");
  button.dataset["utils-DatetimePickerTarget"] = "button";
  button.dataset.action = "click->utils--datetime-picker#openDatetimePicker";
  button.setAttribute("class", "h5-secondary-left-regular");

  // Connect the current date input to the new date picker
  div.addEventListener("utils--datetime-picker:dateSelected", (evt) => {
    const { date } = evt.detail;
    if (date === null) {
      inputTarget.value = ""; /* eslint-disable-line no-param-reassign */
    } else {
      const offset = date.getTimezoneOffset();
      const dateToFormat = new Date(date.getTime() - offset * 60 * 1000);
      const dateStr = dateToFormat.toISOString().slice(0, 10);
      inputTarget.value = dateStr; /* eslint-disable-line no-param-reassign */
    }
    inputTarget.dispatchEvent(new Event("change"));

    if (inputTarget.checkValidity() === false) {
      button.classList.add("invalid");
    } else {
      button.classList.remove("invalid");
    }
  });

  div.append(button);
  inputTarget.classList.add("hidden");
  inputTarget.after(div);
};

/**
 * Enhance select elements in forms to transform them in dropdowns.
 */
export const useDatetimePicker = (controller, inputTarget, options = {}) => {
  replaceInput(inputTarget, options);
};
