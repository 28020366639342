import { Controller } from "@hotwired/stimulus";
import { useTextarea, useSidePanelContent, useDialog } from "../../behaviors";

export default class extends Controller {
  static targets = [
    "contentFieldElement",
    "contentHelpText",
    "contentErrorList",
    "contentFieldWrapper",
    "submitButton",
    "dialogWrapper",
    "dialogOverlay",
  ];

  static classes = [
    "errorFieldWrapper",
    "errorHelpText",
    "hidden",
    "textareaValidEmpty",
  ];

  connect() {
    useTextarea(
      this,
      "content",
      "contentFieldElementTarget",
      "contentHelpTextTarget",
      "contentFieldWrapperTarget",
      "errorFieldWrapperClass",
      "errorHelpTextClass",
    );
    useSidePanelContent(this, {
      elementToFocus: this.contentFieldElementTarget,
    });
    useDialog(this);
  }

  clickOutside(evt) {
    this.openDialog(evt);
  }

  shouldBypassDialog() {
    return this.numberOfCharactersFor_content === 0;
  }
}
