/* eslint import/prefer-default-export: "off" */

import { UnauthorizedError, UnknownHTTPError } from "./errors";
import { getCookie } from "../cookies";

const generateErrorFromHttpResponse = async (response) => {
  let message = "";
  try {
    message = await response.text();
  } catch (e) {
    message = response.statusText;
  }
  let error;
  switch (response.status) {
    case 401:
      error = new UnauthorizedError(response.status, message, response);
      break;
    default:
      error = new UnknownHTTPError(response.status, message, response);
  }
  return error;
};

export const request = async (method, endpoint, data) => {
  let body;
  const headers = {};

  try {
    if (data) {
      body = JSON.stringify(data);
      headers["Content-Type"] = "application/json";

      const csrftokenValue = getCookie("csrftoken");
      if (csrftokenValue) {
        // This is safe to do as we know that the csrf token cookie exists
        headers["X-CSRFToken"] = csrftokenValue;
      }
    }

    const options = { method, headers, body, credentials: "same-origin" };

    const response = await fetch(endpoint, options);
    if (response.ok) {
      if (response.status === 204) {
        return "";
      }
      return response.json();
    }
    const error = await generateErrorFromHttpResponse(response);
    console.error(error); // eslint-disable-line no-console
    throw error;
  } catch (e) {
    console.error(e); // eslint-disable-line no-console
    throw e;
  }
};
