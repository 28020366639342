import { Controller } from "@hotwired/stimulus";
import { logEvent } from "../../analytics/amplitude";
import { splitTurboRef } from "../../utils/object_refs";

export default class extends Controller {
  static targets = ["content"];

  static values = {
    copyDisplayContentSuccessMessageCopy: String,
    copyDisplayContentErrorMessageCopy: String,
    objectRef: String,
  };

  async copyDisplayContent(evt) {
    evt.preventDefault();
    const plainContent = this.contentTarget.innerText;
    const richContent = this.contentTarget.innerHTML;
    const messagesController =
      this.application.getControllerForElementAndIdentifier(
        document.getElementById("messages"),
        "messages",
      );
    let copyWasSuccessful = false;
    try {
      const clipboardItem = new ClipboardItem({
        "text/html": new Blob([richContent], { type: "text/html" }),
        "text/plain": new Blob([plainContent], { type: "text/plain" }),
      });
      await navigator.clipboard.write([clipboardItem]);
      const successMessage =
        this.copyDisplayContentSuccessMessageCopyValue ||
        "Copied to clipboard!";
      messagesController.flash({
        detail: { content: successMessage, status: "success" },
      });
      copyWasSuccessful = true;

      this.logEvent();
    } catch (e) {
      console.error(e); /* eslint-disable-line no-console */
      if (copyWasSuccessful === false) {
        const errorMessage =
          this.copyDisplayContentErrorMessageCopyValue ||
          "Failed to copy to clipboard!";

        messagesController.flash({
          detail: { content: errorMessage, status: "error" },
        });
      }
    }
  }

  logEvent() {
    const [objectType, objectId, objectShortType] = splitTurboRef(
      this.objectRefValue,
    );
    const objectTypeForKey =
      objectShortType === "reportentry" ? "note" : objectShortType;

    const evtKey = `${objectTypeForKey}.copyContentToClipboard`;
    logEvent(evtKey, { objectId, objectType });
  }
}
