import { Controller } from "@hotwired/stimulus";
import { useMenuPanel } from "../../behaviors";

export default class MenuController extends Controller {
  static classes = ["navActive"];

  static targets = ["nav", "menuButton"];

  connect() {
    if (this.hasMenuButtonTarget) {
      useMenuPanel(
        this,
        { menuButtonTargetName: "menuButton", navTargetName: "nav" },
        { navActiveClassName: "navActive" },
        { callEvtPreventDefaultOnClose: false },
      );
    }
  }

  toggleMenu() {
    const dontToggle =
      window.matchMedia("screen and (min-width: 80em)").matches &&
      this.navTarget.classList.contains(this.navActiveClass) === false;

    return !dontToggle;
  }

  navTargetDisconnected() {
    // Ensure other controllers are made aware when there is a navigation event
    // and the nav target gets disconnect (which means that the main menu has
    // been rerendere and consequently closed)
    if (this.hasMenuButtonTarget) {
      this.navTarget.classList.remove(this.navActiveClass);
    }
    this.dispatch("menuClosed");
  }
}
