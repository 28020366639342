import SimplePicker from "simplepicker";

export default class DatetimePicker extends SimplePicker {
  init(el, opts) {
    super.init(el, opts);
  }

  selectDateElement(el) {
    super.selectDateElement(el);
    el.classList.add("active");
  }

  disableTimeSection() {
    super.disableTimeSection();
    const { $ } = this;
    const simplePickerSelectPane = $(".simplepicker-select-pane");
    simplePickerSelectPane.style.display = "none";
  }

  enableTimeSection() {
    const { $ } = this;
    const simplePickerSelectPane = $(".simplepicker-select-pane");
    simplePickerSelectPane.style.display = "block";
  }
}
