import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from "../behaviors";

export default class TooltipMenuController extends Controller {
  static targets = [
    "jsRequiredElement",
    "tooltipMenu",
    "tooltipMenuButton",
    "tooltipMenuContainer",
  ];

  static classes = [
    "tooltipMenuHidden",
    "jsRequiredHidden",
    "tooltipMenuLocked",
    "tooltipMenuClickable",
    "tooltipMenuOpen",
  ];

  connect() {
    this.jsRequiredElementTargets.forEach((el) => {
      el.classList.remove(this.jsRequiredHiddenClass);
    });
    this.tooltipMenuContainerTarget.classList.add(
      this.tooltipMenuClickableClass,
    );

    useClickOutside(this);
  }

  clickOutside() {
    this.close();
  }

  open() {
    this.tooltipMenuContainerTarget.classList.add(this.tooltipMenuOpenClass);
  }

  /**
   * Forces the tooltip menu to close by temporarily adding the `hidden` class
   * on it.
   * The class is removed in the next _tick_ to make sure the menu will be
   * usable again the next time the user hovers the button that shows the menu.
   */
  close() {
    this.tooltipMenuTarget.classList.add(this.tooltipMenuHiddenClass);
    this.tooltipMenuContainerTarget.classList.remove(this.tooltipMenuOpenClass);
    setTimeout(() => {
      this.tooltipMenuTarget.classList.remove(this.tooltipMenuHiddenClass);
    }, 25);
  }

  toggle() {
    if (
      this.tooltipMenuContainerTarget.classList.contains(
        this.tooltipMenuOpenClass,
      )
    ) {
      this.close();
    } else {
      this.open();
    }
  }

  lock() {
    this.tooltipMenuTarget.classList.add(this.tooltipMenuLockedClass);
  }

  unlock() {
    this.tooltipMenuTarget.classList.remove(this.tooltipMenuLockedClass);
  }
}
