import { Controller } from "@hotwired/stimulus";

/**
 * Controller for the side panel
 * Please note that it always the responsability of the side panel conent
 * to close the side panel.
 */
export default class SidePanelController extends Controller {
  static classes = ["active"];

  static targets = ["sidePanelContent", "sidePanel", "sidePanelWrapper"];

  sidePanelContentTargetConnected() {
    if (this.sidePanelContentTarget.childElementCount > 0) {
      this.sidePanelTarget.classList.add(this.activeClass);
      this.dispatch("sidePanelOpened");
    }
  }

  sidePanelContentTargetDisconnected() {
    this.sidePanelTarget.classList.remove(this.activeClass);
    this.dispatch("sidePanelClosed");
  }

  close(evt) {
    this.sidePanelTarget.classList.remove(this.activeClass);
    this.dispatch("sidePanelClosed");
    if (window.Turbo) {
      // if Turbo and stimulus are active we don't need the
      // navigation in this case as we are staying on the same page.
      evt.preventDefault();
    }
  }
}
