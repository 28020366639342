/* eslint import/prefer-default-export: "off" */

const defaultOptions = {
  callEvtPreventDefaultOnClose: true,
  callEvtPreventDefaultOnOpen: true,
};

export const useMenuPanel = (controller, targetNames, classNames, options) => {
  const { callEvtPreventDefaultOnClose, callEvtPreventDefaultOnOpen } = {
    ...defaultOptions,
    ...options,
  };

  const { menuButtonTargetName, navTargetName } = targetNames;
  const { navActiveClassName } = classNames;

  const menuButtonTargetPropName = `${menuButtonTargetName}Target`;
  const navTargetPropName = `${navTargetName}Target`;
  const navActiveClassPropName = `${navActiveClassName}Class`;

  Object.assign(controller, {
    menuOpened: controller[menuButtonTargetPropName].classList.contains(
      controller[navActiveClassPropName],
    ),

    toggleMenu_fromBehavior(evt) {
      this[menuButtonTargetPropName].classList.toggle(
        this[navActiveClassPropName],
      );
      this[navTargetPropName].classList.toggle(this[navActiveClassPropName]);

      const isOpened = this[navTargetPropName].classList.contains(
        this[navActiveClassPropName],
      );

      if (isOpened) {
        this.menuOpened = true;
        this.dispatch("menuOpened");
        if (callEvtPreventDefaultOnOpen === true) {
          evt.preventDefault();
        }
      } else {
        this.menuOpened = false;
        this.dispatch("menuClosed");
        if (callEvtPreventDefaultOnClose === true) {
          evt.preventDefault();
        }
      }
    },
  });

  // Mix the controller input handler and the basic text area one.
  const controllerToggleMenu = controller.toggleMenu?.bind(controller);

  Object.assign(controller, {
    toggleMenu(evt) {
      let toggleMenu = true;
      if (controllerToggleMenu) {
        toggleMenu = controllerToggleMenu(evt);
      }
      if (toggleMenu === true) {
        this.toggleMenu_fromBehavior(evt);
      }
    },
  });
};
