/* eslint import/prefer-default-export: "off" */

/**
 * Activates select/unselect all button for a checkbox multi select form widget
 * This is progressive enhancement and the buttons need to be present in the
 * DOM.
 * - Remove the `hidden` class on the buttons
 * - Add some methods on the controller to handle the events on the buttons.
 *   - The method are `checkedCheckboxFor_${fieldName}`,
 *     `checkAllCheckboxesFor_${fieldName}` and `uncheckAllCheckboxes_For${fieldName}`
 */
export const useSelectUnselectAllCheckboxesButtons = (
  controller,
  fieldName,
  selectAllButtonTargetPropName,
  unselectAllButtonTargetPropName,
  checkboxTargetPropName,
  jsRequiredElementTargetPropName,
  jsRequiredHiddenClassPropName,
) => {
  const checkedCheckboxForFieldMethodName = `checkedCheckboxFor_${fieldName}`;
  const checkAllCheckboxesForFieldMethodName = `checkAllCheckboxesFor_${fieldName}`;
  const uncheckAllCheckboxesForFieldMethodName = `uncheckAllCheckboxesFor_${fieldName}`;

  Object.assign(controller, {
    [checkedCheckboxForFieldMethodName]() {
      const checkboxTargets = this[`${checkboxTargetPropName}s`];
      const selectAllButtonTarget = this[selectAllButtonTargetPropName];
      const unselectAllButtonTarget = this[unselectAllButtonTargetPropName];

      const checkedCheckboxes = checkboxTargets.filter(
        (el) => el.checked === true,
      );
      const allCheckboxesChecked =
        checkedCheckboxes.length === checkboxTargets.length;
      const allCheckboxesUnchecked = checkedCheckboxes.length === 0;

      if (allCheckboxesChecked) {
        selectAllButtonTarget.disabled = true;
        unselectAllButtonTarget.disabled = false;
      }
      if (allCheckboxesUnchecked) {
        unselectAllButtonTarget.disabled = true;
        selectAllButtonTarget.disabled = false;
      }
    },

    [checkAllCheckboxesForFieldMethodName]() {
      const checkboxTargets = this[`${checkboxTargetPropName}s`];
      const selectAllButtonTarget = this[selectAllButtonTargetPropName];
      const unselectAllButtonTarget = this[unselectAllButtonTargetPropName];
      checkboxTargets.forEach((el) => {
        el.checked = true; // eslint-disable-line no-param-reassign
      });
      selectAllButtonTarget.disabled = true;
      unselectAllButtonTarget.disabled = false;
    },

    [uncheckAllCheckboxesForFieldMethodName]() {
      const checkboxTargets = this[`${checkboxTargetPropName}s`];
      const selectAllButtonTarget = this[selectAllButtonTargetPropName];
      const unselectAllButtonTarget = this[unselectAllButtonTargetPropName];
      checkboxTargets.forEach((el) => {
        el.checked = false; // eslint-disable-line no-param-reassign
      });
      unselectAllButtonTarget.disabled = true;
      selectAllButtonTarget.disabled = false;
    },
  });

  // Show the buttons
  controller[`${jsRequiredElementTargetPropName}s`].forEach((el) => {
    el.classList.remove(controller[jsRequiredHiddenClassPropName]);
  });

  controller[checkedCheckboxForFieldMethodName]();
};
