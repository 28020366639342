import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import { trackEventWithDataToAmplitudeAndGoogle } from "../../analytics/global_analytics";

export default class extends Controller {
  static values = {
    workInteractionId: String,
    workStreamId: String,
    eventId: String,
    recommendationTitle: String,
  };

  // Yesterdays calls
  copyActionsAndObjections(event) {
    event.preventDefault();
    trackEventWithDataToAmplitudeAndGoogle(
      "Copy",
      "My Kaizan",
      "Actions and Objections - Copy",
      { workInteractionId: this.workInteractionIdValue },
    );
  }

  shareActionsAndObjections() {
    trackEventWithDataToAmplitudeAndGoogle(
      "Share",
      "My Kaizan",
      "Actions and Objections - Share",
      { workInteractionId: this.workInteractionIdValue },
    );

    $("#shareWith").modal("show");
  }

  // Week events
  viewLastMeeting() {
    trackEventWithDataToAmplitudeAndGoogle(
      "View Last Meeting",
      "My Kaizan",
      "Week Events - View Last Meeting",
      { workStreamId: this.workStreamId, eventId: this.eventId },
    );
  }

  removeBots() {
    trackEventWithDataToAmplitudeAndGoogle(
      "Remove Bots",
      "My Kaizan",
      "Week Events - Remove Bots",
      { eventId: this.eventId },
    );
  }

  // Recommendations
  recommendationMainActionClick(event) {
    const eventName = $(event.target).data("action-title");
    const eventCategory = "My Kaizan";
    const eventLabel = `Recommendations - ${this.recommendationTitleValue}`;
    const eventData = {
      recommendationTitle: this.recommendationTitleValue,
      workStreamId: this.workStreamIdValue,
    };
    trackEventWithDataToAmplitudeAndGoogle(
      eventName,
      eventCategory,
      eventLabel,
      eventData,
    );
  }

  generateEmail() {
    trackEventWithDataToAmplitudeAndGoogle(
      "Generate Email",
      "My Kaizan",
      "Recommendations - Generate Email",
      {
        recommendationTitle: this.recommendationTitleValue,
        workStreamId: this.workStreamIdValue,
      },
    );
  }

  copyEmail() {
    trackEventWithDataToAmplitudeAndGoogle(
      "Copy Email",
      "My Kaizan",
      "Recommendations - Generate Email",
      {
        recommendationTitle: this.recommendationTitleValue,
        workStreamId: this.workStreamIdValue,
      },
    );
  }
}
