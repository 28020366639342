/* eslint import/prefer-default-export: "off" */
import { logEvent } from "./amplitude";
import { trackEventWithData } from "./google_analytics";

export const trackEventWithDataToAmplitudeAndGoogle = (
  eventName,
  eventCategory,
  eventLabel,
  eventData = {},
) => {
  // Log in Amplitude
  logEvent(`${eventCategory}.${eventName}`, eventData);
  // Log in Google Analytics
  trackEventWithData(eventName, eventCategory, eventLabel, eventData);
};
