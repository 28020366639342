/* eslint import/prefer-default-export: "off" */

/**
 * Simple method to interpolate strings dynamically.
 * It can be used for simple cases of internalisation with template strings
 * being backend generated. For example
 * - Django template
 * ```
 * <div
 *   data-controller="foo"
 *   data-foo-my-template-string-value="{% translate "Hello ${this.name}!" %}
 *   ...
 * ```
 *
 * - Stimurlus controller
 * ```
 *   static values = {
 *     myTemplateString: String
 *   }
 *   ...
 *
 *   aMethod() {
 *     const str = fillTemplate(this.myTemplateStringValue, { name: "World" });
 *     // -> Hello World!
 *     ...
 *   }
 * ```
 */
export const fillTemplate = (templateString, templateVars) =>
  // eslint-disable-next-line no-new-func, prefer-template
  new Function("return `" + templateString + "`;").call(templateVars);
