import { Controller } from "@hotwired/stimulus";
import { trackPageView } from "../../analytics/google_analytics";
import { useEmailAnalytics } from "../../behaviors";

export default class extends Controller {
  connect() {
    useEmailAnalytics(this);
  }

  /* eslint-disable-next-line class-methods-use-this */
  trackPageView(evt) {
    const url = evt.currentTarget.href;
    trackPageView(url);
  }

  /* eslint-disable-next-line class-methods-use-this */
  trackCurrentPage() {
    const url = window.location.href;
    trackPageView(url);
  }
}
