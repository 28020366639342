/* Import CSS */
import "simplepicker/dist/simplepicker.css";
import "../scss/config/bootstrap.scss";
import "../scss/config/app.scss";

/* Import Javascript */
import "./sentry";
import "./analytics/amplitude";
import "./analytics/google_analytics";
import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";
import "./app";
import "./integration-app";

const application = Application.start();
const context = require.context("./controllers", true, /\.js$/);
application.load(definitionsFromContext(context));

if (!window.Turbo) {
  const Turbo = require("@hotwired/turbo"); // eslint-disable-line
  Turbo.start();
}

// application.debug = true;
// application.handleError = (error, message, detail) => {
// console.warn(message, detail);
// };
