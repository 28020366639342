import { Controller } from "@hotwired/stimulus";
import { patchUser } from "../../daos/people";
import { readJsonScriptValue } from "../../utils/json_script";

export default class TimezoneController extends Controller {
  /* eslint-disable-next-line class-methods-use-this */
  async connect() {
    const userTimezoneScriptEl = document.getElementById("user-timezone");
    if (userTimezoneScriptEl === null) {
      return;
    }
    // Gets the current user timezone from local storage
    let userTimezone;

    try {
      userTimezone = readJsonScriptValue("user-timezone", {
        throws: true,
      });
    } catch (e) {
      return;
    }

    // Gets the current user timezone from the browser
    const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Checks if need to be updated
    const updateUserTimezone = userTimezone !== clientTimezone;
    if (updateUserTimezone) {
      try {
        await patchUser({ timezone: clientTimezone });
      } catch (e) {
        console.error(e); // eslint-disable-line no-console
      }
    }
  }
}
