/* eslint import/prefer-default-export: "off" */
import { useClickOutside } from "./use_click_outside";

const defaultOptions = {
  elementToFocus: null,
  clickOutsideTargetElement: null,
};

export const useSidePanelContent = (controller, options = {}) => {
  const { elementToFocus, clickOutsideTargetElement } = {
    ...defaultOptions,
    ...options,
  };

  // Small hack to prevent the cursor in the textarea of shacking...
  // Setting "autofocus" directly on the field breaks the transition
  // https://stackoverflow.com/a/40933621
  // We need to set focus at the end of the side panel transition. We cannot
  // use the Stimulus event system as controllers are only able to bubble
  // event to their parents...
  // 500ms is the current duration of the transition
  // TODO: find a way to share this value between JS and CSS
  if (elementToFocus) {
    setTimeout(() => elementToFocus.focus(), 500);
  }

  useClickOutside(controller, {
    element: document.getElementById("side-panel-wrapper"),
    targetElement: clickOutsideTargetElement,
  });
};
