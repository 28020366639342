import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import { updateWindowLocationSearch } from "../../behaviors";

export default class extends Controller {
  static values = {
    currentThreadId: String,
    newThreadUrl: String,
  };

  static targets = ["threadList"];

  connect() {
    if (this.currentThreadIdValue) {
      this.threadListTarget
        .querySelectorAll(".list-group-item")
        .forEach((item) => {
          if ($(item).data("thread-id") === this.currentThreadIdValue) {
            item.classList.add("active");
          } else {
            item.classList.remove("active");
          }
        });
    }
  }

  activate(event) {
    const clickedThreadId = $(event.currentTarget).data("thread-id");
    // Remove active class from all links
    this.threadListTarget
      .querySelectorAll(".list-group-item")
      .forEach((item) => {
        item.classList.remove("active");
      });

    // Add active class to the clicked link
    event.currentTarget.classList.add("active");
    this.currentThreadIdValue = clickedThreadId;
    updateWindowLocationSearch(this);
  }

  // used by the updateWindowLocationSearch to get the new search params
  getNewUrlEncodedQueryStringValue() {
    return `thread_id=${this.currentThreadIdValue}`;
  }

  newThread() {
    // update the location href in the url by removing the thread ID
    this.currentThreadIdValue = "";
    updateWindowLocationSearch(this);
    // reload the thread messages turbo frame
    $("#chatbot-interactions-thread-messages").attr(
      "src",
      this.newThreadUrlValue,
    );
  }
}
