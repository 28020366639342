import { Controller } from "@hotwired/stimulus";
import { useTextarea, useSidePanelContent, useDialog } from "../../behaviors";

export default class extends Controller {
  static targets = [
    "contentFieldElement",
    "contentHelpText",
    "contentErrorList",
    "contentFieldWrapper",
    "submitButton",
    "dialogWrapper",
    "dialogOverlay",
  ];

  static classes = ["errorFieldWrapper", "errorHelpText", "hidden"];

  connect() {
    useTextarea(
      this,
      "content",
      "contentFieldElementTarget",
      "contentHelpTextTarget",
      "contentFieldWrapperTarget",
      "errorFieldWrapperClass",
      "errorHelpTextClass",
      { addCounter: false },
    );
    useSidePanelContent(this, {
      elementToFocus: this.contentFieldElementTarget,
    });
    useDialog(this);
  }

  clickOutside(evt) {
    this.openDialog(evt);
  }

  shouldBypassDialog() {
    const content = this.contentFieldElementTarget.value.trim();
    const originalContent =
      this.contentFieldElementTarget.dataset.initialValue?.trim() || "";

    const bypassDialog = content === originalContent;
    return bypassDialog;
  }
}
