import DropdownController from "./dropdown_controller";

export default class FilterController extends DropdownController {
  /**
   * Method called inside `onSelectChange`.
   */
  setButtonTargetValue(element) {
    this.buttonTarget.innerHTML = `${this.labelValue.trim()}: ${
      element.innerHTML
    }`;
  }
}
