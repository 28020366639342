/* eslint import/prefer-default-export: "off" */
import $ from "jquery";
import { flashMessage } from "../controllers/messages_controller";

export const StreamingResponseEventType = {
  SUBMIT_START: "SUBMIT_START",
  SUBMIT_END: "TYPE_B",
  RESPONSE_RECEIVED: "RESPONSE_RECEIVED",
  PARTIAL_RESPONSE_RECEIVED: "PARTIAL_RESPONSE_RECEIVED",
  STREAMING_PROCESS_END: "STREAMING_PROCESS_END",
};

/**
 * Allow a controller to use streamed response. Overrides the form submit and sends a POST
 * request to the form url with a body defined and handles the response by reding it in chunks.
 * The controller must have the following targets:
 * - formTarget - The form that will be used to submit the data.
 *
 * The controller must have the following methods:
 * - handleStreamingResponseEvent - Handles the streaming response events.
 * - getQueryString - Returns the query string of the form data.
 */
export const useStreamedResponse = (controller) => {
  Object.assign(controller, {
    submit(event) {
      event.preventDefault();
      controller.startStreaming(controller.getQueryString());
    },

    startStreaming(queryString) {
      const formUrl = controller.formTarget.getAttribute("action");

      controller.handleStreamingResponseEvent(
        StreamingResponseEventType.SUBMIT_START,
      );

      fetch(formUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-CSRFToken": $(controller.formTarget)
            .find("input[name=csrfmiddlewaretoken]")
            .val(),
        },
        body: queryString,
      })
        .then((response) => {
          const reader = response.body.getReader();

          controller.handleStreamingResponseEvent(
            StreamingResponseEventType.RESPONSE_RECEIVED,
          );
          reader.read().then(function processText({ done, value }) {
            if (done) {
              controller.handleStreamingResponseEvent(
                StreamingResponseEventType.SUBMIT_END,
              );
              return;
            }

            controller.handleStreamingResponseEvent(
              StreamingResponseEventType.PARTIAL_RESPONSE_RECEIVED,
              value,
            );
            reader.read().then(processText);
          });
        })
        .catch((error) => {
          /* eslint-disable-next-line no-console */
          console.error(
            "There has been a problem receiving the streamed response:",
            error,
          );
          flashMessage(
            this.application,
            "Error receiving the response. Please contact Kaizan if the error persists.",
            "error",
          );
        })
        .finally(() => {
          controller.handleStreamingResponseEvent(
            StreamingResponseEventType.STREAMING_PROCESS_END,
          );
        });
    },

    initStreamedResponse() {
      this.formTarget.addEventListener("submit", this.submit.bind(this));
    },
  });

  controller.initStreamedResponse();
};
