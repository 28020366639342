/* eslint import/prefer-default-export: "off" */
const BUTTON_TAG_CLASSES = {
  unset: "h7-secondary-left-regular-variant",
  set: "h7-secondary-left-regular",
  overdue: "h7-negative-left-regular negative",
};

const getButtonTagClasses = (inputTarget, isOverdue = null) => {
  let buttonType = "unset";
  const hasDueDate = inputTarget.value !== "";

  let isOverdueDate = isOverdue;
  if (hasDueDate && isOverdue === null) {
    const now = new Date();
    const offset = now.getTimezoneOffset();
    const today = new Date(now.getTime() - offset * 60 * 1000)
      .toISOString()
      .split("T")[0];
    isOverdueDate = inputTarget.value < today;
  }
  if (hasDueDate === true) {
    buttonType = isOverdueDate ? "overdue" : "set";
  }

  const buttonTagClasses = BUTTON_TAG_CLASSES[buttonType];
  return buttonTagClasses;
};

const replaceInput = (
  formTarget,
  inputTarget,
  submitButtonTarget,
  isOverdue,
  datePickerOptions,
) => {
  const buttonTagClasses = getButtonTagClasses(inputTarget, isOverdue);

  const ul = document.createElement("ul");
  ul.dataset.controller = "utils--datetime-picker";
  ul.dataset["utils-DatetimePickerPlaceholderValue"] = inputTarget.placeholder;
  ul.dataset["utils-DatetimePickerInitialValue"] = inputTarget.value;
  ul.dataset["utils-DatetimePickerMinValue"] = inputTarget.min;
  ul.dataset["utils-DatetimePickerDatetimePickerRootElementSelectorValue"] =
    datePickerOptions.rootElementSelector;
  ul.dataset["utils-DatetimePickerMaxValue"] = inputTarget.max;
  ul.dataset["utils-DatetimePickerDisableTimeSectionValue"] =
    datePickerOptions.disableTimeSection;

  ul.setAttribute("class", "button-tag-list datetime-picker");

  const li = document.createElement("li");
  li.dataset["utils-DatetimePickerTarget"] = "button";
  li.dataset.action = "click->utils--datetime-picker#openDatetimePicker";
  li.setAttribute("class", buttonTagClasses);

  // Connect the current date input to the new date picker
  ul.addEventListener("utils--datetime-picker:dateSelected", (evt) => {
    const { date } = evt.detail;

    if (date === null) {
      inputTarget.value = ""; /* eslint-disable-line no-param-reassign */
      li.innerText = submitButtonTarget.innerText.trim();
    } else {
      const offset = date.getTimezoneOffset();
      const dateToFormat = new Date(date.getTime() - offset * 60 * 1000);
      const dateStr = dateToFormat.toISOString().slice(0, 10);
      inputTarget.value = dateStr; /* eslint-disable-line no-param-reassign */
    }

    const newButtonTagClasses = getButtonTagClasses(inputTarget);
    li.setAttribute("class", newButtonTagClasses);
    inputTarget.dispatchEvent(new Event("change"));
  });

  ul.append(li);
  formTarget.classList.add("hidden");
  formTarget.after(ul);
};

/**
 * Enhance select elements in forms to transform them in dropdowns.
 */
export const useDatetimePickerAsButtonTag = (
  controller,
  formTarget,
  inputTarget,
  submitButtonTarget,
  isOverdue,
  datePickerOptions = {},
) => {
  replaceInput(
    formTarget,
    inputTarget,
    submitButtonTarget,
    isOverdue,
    datePickerOptions,
  );
};
