import { Controller } from "@hotwired/stimulus";
import KeyValues from "../../utils/key_values";

export default class DropdownController extends Controller {
  static targets = ["button", "listbox"];

  static hiddenClass = "hidden";

  static values = {
    label: String,
  };

  connect() {
    this.buttonTarget.classList.remove("hidden");
    this.registerEvents();
  }

  registerEvents() {
    this.buttonTarget.addEventListener("click", this.showListbox.bind(this));
    this.buttonTarget.addEventListener("keyup", this.checkShow.bind(this));
    this.listboxTarget.addEventListener("blur", this.hideListbox.bind(this));
    this.listboxTarget.addEventListener("keydown", this.checkHide.bind(this));
  }

  checkShow(evt) {
    const { key } = evt;

    switch (key) {
      case KeyValues.ARROW_UP:
      case KeyValues.UP:
      case KeyValues.ARROW_DOWN:
      case KeyValues.DOWN:
        evt.preventDefault();
        this.showListbox();
        break;
      default:
        break;
    }
  }

  checkHide(evt) {
    const { key } = evt;

    switch (key) {
      case KeyValues.ENTER:
      case KeyValues.ESC:
      case KeyValues.ESCAPE:
        evt.preventDefault();
        this.hideListbox();
        this.buttonTarget.focus();
        break;
      default:
        break;
    }
  }

  showListbox(evt) {
    if (evt) {
      evt.preventDefault();
    }
    const html = document.documentElement;

    // Gets the whole page height
    const pageHeight = html.clientHeight;

    const buttonDistanceToBottom =
      pageHeight - this.buttonTarget.getBoundingClientRect().bottom;

    // Display the list and move it up to float over the button if necessary
    this.listboxTarget.classList.remove(DropdownController.hiddenClass);
    this.buttonTarget.setAttribute("aria-expanded", "true");
    const listboxHeight = this.listboxTarget.offsetHeight;

    if (buttonDistanceToBottom < listboxHeight) {
      this.listboxTarget.style.marginTop = `${
        -(listboxHeight - buttonDistanceToBottom + 16) / 10
      }rem`;
    } else {
      this.listboxTarget.style.marginBottom = 0;
    }

    this.listboxTarget.focus();
  }

  hideListbox() {
    this.listboxTarget.classList.add(DropdownController.hiddenClass);
    this.buttonTarget.removeAttribute("aria-expanded");
  }

  /**
   * Method called inside `onSelectChange`.
   */
  setButtonTargetValue(element) {
    this.buttonTarget.innerHTML = element.innerHTML;
  }

  onSelectChange(evt) {
    const {
      detail: { element },
    } = evt;

    this.setButtonTargetValue(element);

    // Propagate the classes from the select...
    const { classList = "" } = element;
    if (this.buttonTarget.dataset.extraClasses) {
      this.buttonTarget.dataset.extraClasses.split(" ").forEach((el) => {
        this.buttonTarget.classList.remove(el);
      });
    }
    this.buttonTarget.dataset.extraClasses = classList.value;

    this.hideListbox();
  }
}
