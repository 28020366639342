/* eslint import/prefer-default-export: "off" */
import { readJsonScriptValue } from "../utils/json_script";
import { getTitleFromLocation } from "./utils";

const googleAnalyticsKey = readJsonScriptValue("settings-google-analytics-key");
const userId = readJsonScriptValue("user-id");

window.dataLayer = window.dataLayer || [];

/* eslint-disable-next-line no-inner-declarations */
function gtag() {
  window.dataLayer.push(arguments /* eslint-disable-line prefer-rest-params */);
}

if (googleAnalyticsKey !== undefined) {
  gtag("js", new Date());

  gtag("config", googleAnalyticsKey, {
    user_id: userId,
    anonymize_ip: true,
    send_page_view: false,
  });

  document.addEventListener("turbo:load", (evt) => {
    const pageLocation = evt.detail.url;
    const pageTitle = getTitleFromLocation(pageLocation);

    gtag("event", "page_view", {
      page_title: pageTitle,
      page_location: pageLocation,
    });
  });
}

export const trackPageView = (pageLocation) => {
  const pageTitle = getTitleFromLocation(pageLocation);

  gtag("event", "page_view", {
    page_title: pageTitle,
    page_location: pageLocation,
  });
};

export const trackEvent = (eventName, eventCategory, eventLabel) => {
  gtag("event", eventName, {
    event_category: eventCategory,
    event_label: eventLabel,
  });
};

export const trackEventWithData = (
  eventName,
  eventCategory,
  eventLabel,
  eventData = {},
) => {
  gtag("event", eventName, {
    event_category: eventCategory,
    event_label: eventLabel,
    event_data: eventData,
  });
};
