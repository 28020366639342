import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static classes = ["scrollLocked"];

  lockScroll() {
    this.element.classList.add(this.scrollLockedClass);
  }

  unlockScroll() {
    this.element.classList.remove(this.scrollLockedClass);
  }
}
