import { IntegrationAppClient } from "@integration-app/sdk";

async function getToken() {
  const GET_URL = `/api/v2/generate-integration-app-auth`;
  const response = await fetch(GET_URL);
  const data = await response.json();
  return data.token;
}

async function getIntegrationAppClient(token) {
  return new IntegrationAppClient({
    token,
  });
}

function showMessage(message, type = "success") {
  // Select the msg-list element
  const msgList = document.getElementById("messages-list");

  // Create a new li element
  const messageItem = document.createElement("li");

  // Set the class and attributes
  messageItem.className = `message alert alert-${type}`;
  messageItem.setAttribute("data-messages-target", "message");
  messageItem.setAttribute(
    "data-action",
    "animationend->messages#removeMessage click->messages#removeMessage",
  );

  // Set the message text
  messageItem.textContent = message;

  // Append the message item to the msg-list
  msgList.appendChild(messageItem);
}

// eslint-disable-next-line import/prefer-default-export
export async function manageIntegrations() {
  const token = await getToken();
  const integrationApp = await getIntegrationAppClient(token);
  const initialConnections = await integrationApp.connections.find();
  let polling;

  async function pollFieldStatus() {
    const newConnections = await integrationApp.connections.find();
    // If there are no previous connections and there are new connections
    if (
      initialConnections.items.length === 0 &&
      newConnections.items.length > 0
    ) {
      const newConnection = newConnections.items[0];
      // If the new connection is correctly connected
      if (!newConnection.disconnected) {
        const popup = document.getElementById("__integration-app-container");
        if (popup) {
          // Hide CRM connection pop-up, show success message and stop polling
          popup.style.display = "none";
          const connectionMessage = `Successfully connected to ${newConnection.name}`;
          showMessage(connectionMessage);
          clearInterval(polling);
        }
      }
    }
  }

  polling = setInterval(pollFieldStatus, 6000);
  await integrationApp.open({ showPoweredBy: false });
}
