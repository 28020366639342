/* eslint import/prefer-default-export: "off" */
import * as amplitude from "@amplitude/analytics-browser";
import { readJsonScriptValue } from "../utils/json_script";

const amplitudeApiKey = readJsonScriptValue("settings-amplitude-api-key");
const userIdEmailAddress = readJsonScriptValue("user-email-address", {
  defaultValue: null,
});
const version = readJsonScriptValue("settings-source-version");

const amplitudeOptions = {
  autocapture: true,
  appVersion: version,
};

amplitude.init(amplitudeApiKey, userIdEmailAddress, amplitudeOptions);

const extendEventProperties = (properties) => ({
  ...properties,
  context: "Web Application",
});

export const logEvent = (evt, properties = {}) => {
  const eventProperties = extendEventProperties(properties);
  amplitude.track(evt, eventProperties);
};

logEvent("page.load", { url: window.location.href });
