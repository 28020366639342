import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tabLink", "tabsListFrame"];

  connect() {
    // Swicthes the frame target to have a more targeted navigation.
    const alternateFrameTarget = this.tabsListFrameTarget.getAttribute(
      "data-alternate-frame-target",
    );
    this.tabsListFrameTarget.setAttribute("target", alternateFrameTarget);
  }

  selectTab(evt) {
    const targetUrl = evt.target.dataset.href;
    this.tabLinkTargets.forEach((el) => {
      const tabUrl = el.dataset.href;
      const { selectedClass } = el.parentElement.dataset;
      if (tabUrl === targetUrl) {
        el.parentElement.classList.add(selectedClass);
      } else {
        el.parentElement.classList.remove(selectedClass);
      }
    });
    this.dispatch("tabSelected", { detail: { targetUrl } });
  }
}
