/* eslint import/prefer-default-export: "off" */
import { createListBoxElements, ListboxType } from "./utils/listbox";

const replaceSelect = (selectTarget, labelTarget) => {
  // Create the DOM of the new Dropdown to append after the select
  const { ul, button } = createListBoxElements(
    ListboxType.FILTER,
    selectTarget,
    labelTarget,
  );
  ul.setAttribute("class", "h6-secondary-left-regular-variant");
  button.setAttribute("class", "h7-secondary-left-regular-variant");
};

/**
 * Enhance select elements in forms to transform them in filters.
 *
 * This behavior is using interanlly the `utils--filter` controller defined in
 * the `assets/js/controllers/utils/filter_controller.js` file.
 */
export const useFilter = (controller, selectTarget, labelTarget) => {
  replaceSelect(selectTarget, labelTarget);
};
