import { Controller } from "@hotwired/stimulus";
import {
  useDropdown,
  useSidePanelContent,
  useSelectUnselectAllCheckboxesButtons,
} from "../../behaviors";

export default class extends Controller {
  static targets = [
    "orderingFieldElement",
    "selectAllButtonFor_user",
    "unselectAllButtonFor_user",
    "checkboxFor_user",
    "jsRequiredElementFor_user",
  ];

  static classes = ["jsRequiredHidden"];

  connect() {
    useDropdown(this, this.orderingFieldElementTarget);
    useSelectUnselectAllCheckboxesButtons(
      this,
      "user",
      "selectAllButtonFor_userTarget",
      "unselectAllButtonFor_userTarget",
      "checkboxFor_userTarget",
      "jsRequiredElementFor_userTarget",
      "jsRequiredHiddenClass",
    );
    useSidePanelContent(this);
  }

  clickOutside() {
    this.dispatch("click:outside");
  }
}
