import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["lightDarkMode", "hamburgerMenu"];

  connect() {
    this.setTheme();
  }

  setTheme() {
    const theme = localStorage.getItem("data-bs-theme");
    if (theme) {
      document.documentElement.setAttribute("data-bs-theme", theme);
      if (theme === "dark") {
        this.lightDarkModeTarget.innerText = "dark_mode";
      } else {
        this.lightDarkModeTarget.innerText = "light_mode";
      }
    }

    const menuState = localStorage.getItem("menu-state");
    if (menuState) {
      this.setSideBarState(menuState);
    }
  }

  onThemeChange() {
    const theme = localStorage.getItem("data-bs-theme");
    if (theme === "dark") {
      localStorage.setItem("data-bs-theme", "light");
    } else {
      localStorage.setItem("data-bs-theme", "dark");
    }
    this.setTheme();
  }

  onHamburgerMenuClick() {
    const isOpen = this.hamburgerMenuTarget.classList.toggle("open");
    localStorage.setItem("menu-state", isOpen ? "open" : "close");
    this.setSideBarState(isOpen ? "open" : "close");
  }

  setSideBarState(menuState) {
    const windowSize = document.documentElement.clientWidth;
    if (menuState === "open") {
      this.hamburgerMenuTarget.classList.add("open");
      if (windowSize <= 1025 && windowSize > 767) {
        document.body.classList.remove("vertical-sidebar-enable");
        document.documentElement.setAttribute("data-sidebar-size", "");
      } else if (windowSize > 1025) {
        document.body.classList.remove("vertical-sidebar-enable");
        document.documentElement.setAttribute("data-sidebar-size", "sm");
      } else if (windowSize <= 767) {
        document.body.classList.add("vertical-sidebar-enable");
        document.documentElement.setAttribute("data-sidebar-size", "lg");
      }
    } else {
      this.hamburgerMenuTarget.classList.remove("open");
      if (windowSize <= 1025 && windowSize > 767) {
        document.body.classList.remove("vertical-sidebar-enable");
        document.documentElement.setAttribute("data-sidebar-size", "sm");
      } else if (windowSize > 1025) {
        document.body.classList.remove("vertical-sidebar-enable");
        document.documentElement.setAttribute("data-sidebar-size", "lg");
      } else if (windowSize <= 767) {
        document.body.classList.add("vertical-sidebar-enable");
        document.documentElement.setAttribute("data-sidebar-size", "lg");
      }
    }
  }
}
