import { Controller } from "@hotwired/stimulus";
import {
  useSidePanelContent,
  useSelectUnselectAllCheckboxesButtons,
} from "../../behaviors";

export default class extends Controller {
  static targets = [
    "selectAllButton",
    "unselectAllButton",
    "checkbox",
    "jsRequiredElement",
  ];

  static classes = ["jsRequiredHidden"];

  connect() {
    useSelectUnselectAllCheckboxesButtons(
      this,
      "key_phrase",
      "selectAllButtonTarget",
      "unselectAllButtonTarget",
      "checkboxTarget",
      "jsRequiredElementTarget",
      "jsRequiredHiddenClass",
    );

    useSidePanelContent(this);
  }

  clickOutside() {
    this.dispatch("click:outside");
  }
}
