import { Controller } from "@hotwired/stimulus";
import Choices from "choices.js";
import $ from "jquery";
import "choices.js/src/styles/choices.scss";

export default class extends Controller {
  static targets = [
    "allClientsRadioInput",
    "myClientsRadioInput",
    "tierSelectField",
    "tierForm",
  ];

  static values = {
    urlEncodedQueryString: String,
  };

  connect() {
    /* eslint-disable-next-line no-new */
    new Choices(this.tierSelectFieldTarget, {
      allowHTML: true,
      removeItemButton: true,
      placeholder: true,
    });

    if (sessionStorage.getItem("data-show-my-clients") === "true") {
      this.myClientsRadioInputTarget.checked = true;
      this.showMyClients();
    }
  }

  onTierSelectChange() {
    this.tierFormTarget.submit();
  }

  onAllClientsRadioInputChange(e) {
    if (e.target.value === "on") {
      sessionStorage.setItem("data-show-my-clients", "false");
      this.showAllClients();
    }
  }

  onMyClientsRadioInputChange(e) {
    if (e.target.value === "on") {
      sessionStorage.setItem("data-show-my-clients", "true");
      this.showMyClients();
    }
  }

  showMyClients() {
    const myUserId = $(this.myClientsRadioInputTarget).data("my-user-id");

    /* eslint-disable-next-line func-names */
    $("#workStreamsTableBody tr").each(function () {
      const ownersAttribute = $(this).data("owners");
      if (
        ownersAttribute &&
        ownersAttribute
          .split(",")
          .map((item) => item.trim())
          .includes(myUserId)
      ) {
        $(this).show();
      } else {
        $(this).hide();
      }
    });
  }

  /* eslint-disable-next-line class-methods-use-this */
  showAllClients() {
    /* eslint-disable-next-line func-names */
    $("#workStreamsTableBody tr").each(function () {
      $(this).show();
    });
  }
}
