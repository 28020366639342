import { Controller } from "@hotwired/stimulus";
import { useDialog } from "../../behaviors";

export default class extends Controller {
  static targets = ["dialogWrapper", "dialogOverlay"];

  connect() {
    useDialog(this);
  }
}
