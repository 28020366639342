import { Controller } from "@hotwired/stimulus";
import DatetimePicker from "../../components/datetime_picker";

export default class DatetimePickerController extends Controller {
  static targets = ["button"];

  static values = {
    placeholder: String,
    initial: String,
    min: String,
    max: String,
    disableTimeSection: Boolean,
    datetimePickerRootElementSelector: String,
  };

  static hiddenClass = "hidden";

  static dateFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  connect() {
    const datetimePickerOptions = {
      disableTimeSection: this.disableTimeSectionValue,
      zIndex: 1000,
    };

    const date = this.initialValue ? new Date(this.initialValue) : null;
    this.setButtonValue(date);
    if (date !== null) {
      datetimePickerOptions.selectedDate = date;
    }

    this.datetimePicker = new DatetimePicker(
      this.datetimePickerRootElementSelectorValue,
      datetimePickerOptions,
    );
    this.datetimePicker.on("submit", this.onSelectChange.bind(this));
  }

  openDatetimePicker(evt) {
    if (evt) {
      evt.preventDefault();
    }

    this.buttonTarget.blur();
    this.datetimePicker.open();
  }

  clearValue(evt) {
    evt.preventDefault();
    evt.stopImmediatePropagation();
    this.buttonTarget.blur();
    this.onSelectChange(null);
  }

  onSelectChange(date) {
    this.setButtonValue(date);
    this.dispatch("dateSelected", { detail: { date } });
  }

  setButtonValue(date) {
    if (date === null) {
      this.buttonTarget.innerHTML = this.placeholderValue || "";
    } else {
      const clearButton = document.createElement("span");
      clearButton.setAttribute("class", "input-clear-button");
      clearButton.dataset.action = "click->utils--datetime-picker#clearValue";
      this.buttonTarget.innerHTML = date.toLocaleDateString(
        undefined,
        DatetimePickerController.dateFormatOptions,
      );
      this.buttonTarget.append(clearButton);
    }
  }
}
