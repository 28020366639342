/* eslint import/prefer-default-export: "off" */
import { createListBoxElements, ListboxType } from "./utils/listbox";

const defaultOptions = {
  useAbsolutePosition: false, // Makes it possible for the list of options to
  // float over its parent element.
};

const replaceSelect = (selectTarget, { useAbsolutePosition, smallButton }) => {
  // Create the DOM of the new Dropdown to append after the select
  const { ul, button } = createListBoxElements(
    ListboxType.DROPDOWN,
    selectTarget,
  );
  ul.setAttribute("class", "dropdown-menu");

  const liElements = ul.querySelectorAll("li");
  liElements.forEach((li) => {
    li.classList.add("dropdown-item");
  });

  button.setAttribute("class", "btn btn-secondary dropdown-toggle");
  if (smallButton === true) {
    button.classList.add("btn-sm");
  }
  button.setAttribute("data-bs-toggle", "dropdown");
  button.setAttribute("aria-expanded", "false");

  if (useAbsolutePosition === true) {
    setTimeout(() => {
      const width = button.offsetWidth;
      ul.style.position = "absolute";
      ul.style.width = `${width / 10}rem`;
    }, 0);
  }
};

/**
 * Enhance select elements in forms to transform them in dropdowns.
 *
 * This behavior is using interanlly the `utils--filter` controller defined in
 * the `assets/js/controllers/utils/dropdown.js` file.
 */
export const useDropdown = (controller, selectTarget, options = {}) => {
  // Set smallButton to false by default
  const { useAbsolutePosition, smallButton = false } = {
    ...defaultOptions,
    ...options,
  };
  replaceSelect(selectTarget, { useAbsolutePosition, smallButton });
};
