/* eslint import/prefer-default-export: "off" */
import {
  composeEventName,
  extendedEvent,
  isElementInViewport,
} from "../utils/behaviors";

/*
 * export interface ClickOutsideOptions {
 *   element?: Element
 *   events?: string[]
 *   onlyVisible?: boolean
 *   dispatchEvent?: boolean
 *   eventPrefix?: boolean | string
 * }
 *
 *
 */
const defaultOptions = {
  events: ["mousedown", "touchend"],
  onlyVisible: true,
  dispatchEvent: true,
  eventPrefix: true,
  element: null,
  targetElement: null,
};

export const useClickOutside = (controller, options = {}) => {
  const { onlyVisible, dispatchEvent, events, eventPrefix } = {
    ...defaultOptions,
    ...options,
  };

  const onEvent = (event) => {
    const targetElement =
      options?.targetElement || options?.element || controller.element;

    if (
      targetElement.contains(event.target) ||
      (!isElementInViewport(targetElement) && onlyVisible)
    ) {
      return;
    }

    // call the clickOutside method of the Stimulus controller
    if (controller.clickOutside) {
      controller.clickOutside(event);
    }

    // emit a custom event
    if (dispatchEvent) {
      const eventName = composeEventName(
        "click:outside",
        controller,
        eventPrefix,
      );

      const clickOutsideEvent = extendedEvent(eventName, event, { controller });
      targetElement.dispatchEvent(clickOutsideEvent);
    }
  };

  const observe = () => {
    events?.forEach((event) => {
      window.addEventListener(event, onEvent, false);
    });
  };

  const unobserve = () => {
    events?.forEach((event) => {
      window.removeEventListener(event, onEvent, false);
    });
  };

  // keep a copy of the current disconnect() function of the controller
  // to support composing several behaviors
  const controllerDisconnect = controller.disconnect.bind(controller);

  Object.assign(controller, {
    disconnect() {
      unobserve();
      controllerDisconnect();
    },
  });

  observe();

  return [observe, unobserve];
};
