export default {
  ARROW_DOWN: "ArrowDown",
  ARROW_LEFT: "ArrowLeft",
  ARROW_RIGHT: "ArrowRight",
  ARROW_UP: "ArrowUp",
  DOWN: "Down",
  UP: "Up",
  ENTER: "Enter",
  ESC: "Esc",
  ESCAPE: "Escape",
  BACKSPACE: "Backspace",
  DELETE: "Delete",
  END: "End",
  HOME: "Home",
  TAB: "Tab",
  SPACE: " ",
  SHIFT: "Shift",
};
