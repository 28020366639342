import * as Sentry from "@sentry/browser";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";

import { readJsonScriptValue } from "./utils/json_script";

const dsn = readJsonScriptValue("settings-sentry-dsn-frontend", {
  defaultValue: "",
});
const release = readJsonScriptValue("settings-source-version", {
  defaultValue: "",
});
const environment = readJsonScriptValue("settings-app-stage", {
  defaultValue: "",
});

Sentry.init({
  dsn,
  release,
  environment,
  integrations: [
    new CaptureConsoleIntegration({ levels: ["warn", "error", "assert"] }),
  ],
});
