import { Controller } from "@hotwired/stimulus";

export default class TabNavigationController extends Controller {
  static targets = ["nav", "navItem"];

  connect() {
    this.setInitialActiveTab();
    this.setNavItemClickListeners();
  }

  setInitialActiveTab() {
    /**
     * Get the tab index from the GET parameter and if available set the correct nav item active class.
     * If not, set the active class on the first nav item target
     *
     * Load the active tab link into the tab-content turbo frame
     */
    const urlParams = new URLSearchParams(window.location.search);
    const tabName = urlParams.get("tab");
    let navItem = this.navItemTargets.find(
      (item) => item.querySelector("a").dataset.tabName === tabName,
    );

    if (navItem === undefined) {
      navItem = this.navItemTargets[0]; // eslint-disable-line prefer-destructuring
    }
    const navLink = navItem.querySelector(".nav-link");
    navLink.classList.add("active");

    const tabContentFrame = document.getElementById("tab-content");
    if (tabContentFrame) {
      tabContentFrame.src = navLink.dataset.target;
    }
  }

  setNavItemClickListeners() {
    this.navItemTargets.forEach((item) => {
      item.addEventListener("click", (event) => {
        const newUrlParams = new URLSearchParams(window.location.search);
        newUrlParams.set("tab", event.target.dataset.tabName);
        window.history.replaceState(
          {},
          "",
          `${window.location.pathname}?${newUrlParams}`,
        );
        event.stopPropagation();
      });
    });
  }

  // eslint-disable-next-line class-methods-use-this
  onTabClick(event) {
    event.preventDefault();
    const navLink = event.currentTarget;
    const tabContentFrame = document.getElementById("tab-content");
    if (tabContentFrame) {
      this.showLoadingAnimation();
      tabContentFrame.src = navLink.dataset.target;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  showLoadingAnimation() {
    /**
     * Show a loading animation inside the turbo frame when a new tab is clicked. When the turbo frame
     * finishes loading, the loader will be replaced with the content.
     */
    document.addEventListener("turbo:before-fetch-request", (event) => {
      const turboFrame = document.getElementById("tab-content");
      const loadingAnimation = document.getElementById("tab-loading-animation");
      if (event.target === turboFrame) {
        // Show loading animation inside the turbo frame
        turboFrame.innerHTML = loadingAnimation.innerHTML;
      }
    });
  }
}
