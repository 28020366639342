import { Controller } from "@hotwired/stimulus";

/**
 * Adapted from https://github.com/oleksavyshnivsky/tablesort/
 * TODO: Can be heavily optimised
 *
 * @copyleft Oleksa Vyshnivsky <dying.escape@gmail.com> 2022
 * @license ISC
 * */

/**
 * No defence against any table structure complications or very large tables
 *
 * 	dir: asc | desc
 *	datatype: string | int | float | date
 *
 * */

export default class TableSort extends Controller {
  static targets = ["sortedTable"];

  connect() {
    const ths = this.sortedTableTarget.querySelectorAll(
      "thead tr:last-child th[data-sort]",
    );
    ths.forEach((th) => {
      th.onclick = this.tablesort; // eslint-disable-line no-param-reassign
    });
  }

  /* eslint-disable class-methods-use-this */
  async tablesort(e) {
    const table = e.currentTarget.closest("table");
    // Column for sorting
    const J = Array.from(e.currentTarget.parentNode.children).indexOf(
      e.currentTarget,
    );
    // Data type
    const datatype = e.currentTarget.dataset.sort;
    // Delete sort direction from the column of previous sorting
    const olderTH = table.querySelector("th[data-dir]");
    if (olderTH && olderTH !== e.currentTarget) delete olderTH.dataset.dir;
    // Sorting direction
    const dir = e.currentTarget.dataset.dir // eslint-disable-line no-nested-ternary
      ? e.currentTarget.dataset.dir === "asc"
        ? "desc"
        : "asc"
      : e.currentTarget.dataset.sortDefault
        ? e.currentTarget.dataset.sortDefault
        : "asc";
    e.currentTarget.dataset.dir = dir;

    // Inner table for sorting
    const itable = [];

    // Data to be sorted
    const trs = table.querySelectorAll("tbody tr");
    trs.forEach((tr, i) => {
      itable.push({ tr, values: [] });
      const tds = tr.querySelectorAll("td");
      tds.forEach((td) => {
        let value = td.dataset.sortValue ? td.dataset.sortValue : td.innerText;
        if (datatype === "int") value = parseInt(value, 10);
        else if (datatype === "float") value = parseFloat(value);
        else if (datatype === "date") value = new Date(value);
        itable[i].values.push(value);
      });
    });

    // Inner sorting
    if (datatype === "string") {
      if (dir === "asc") {
        itable.sort((a, b) => `${a.values[J]}`.localeCompare(b.values[J]));
      } else {
        itable.sort((a, b) => -`${a.values[J]}`.localeCompare(b.values[J]));
      }
    } else if (dir === "asc") {
      itable.sort(
        (a, b) =>
          a.values[J] < b.values[J] ? -1 : a.values[J] > b.values[J] ? 1 : 0, // eslint-disable-line no-nested-ternary
      );
    } else {
      itable.sort(
        (a, b) =>
          a.values[J] < b.values[J] ? 1 : a.values[J] > b.values[J] ? -1 : 0, // eslint-disable-line no-nested-ternary
      );
    }

    // Redrawing
    table.querySelector("tbody").innerHTML = "";
    itable.forEach((row) => table.querySelector("tbody").append(row.tr));
  }
}
