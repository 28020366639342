/* eslint import/prefer-default-export: "off" */
/**
 * Takes a turbo ref like `actions.Action-cd79a39b-afa0-41e9-9f54-ff824cc341a9`
 * and splits it in 3 elements:
 * - the object type as `actions.Action` (Django app and model names)
 * - the object id as `cd79a39b-afa0-41e9-9f54-ff824cc341a9`
 * - the short object type as `action` (lower case model name)
 */
export const splitTurboRef = (turboRef) => {
  const parts = turboRef.split("-");
  const objectType = parts.shift();
  const id = parts.join("-");
  const shortType = objectType.split(".")[1].toLowerCase();

  return [objectType, id, shortType];
};
