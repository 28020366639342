import { Controller } from "@hotwired/stimulus";
import {
  useSidePanelContent,
  useSelectUnselectAllCheckboxesButtons,
} from "../../behaviors";

export default class extends Controller {
  static targets = [
    "selectAllButtonFor_user_teammates",
    "unselectAllButtonFor_user_teammates",
    "checkboxFor_user_teammates",
    "jsRequiredElementFor_user_teammates",
  ];

  static classes = ["jsRequiredHidden"];

  connect() {
    useSelectUnselectAllCheckboxesButtons(
      this,
      "user_teammates",
      "selectAllButtonFor_user_teammatesTarget",
      "unselectAllButtonFor_user_teammatesTarget",
      "checkboxFor_user_teammatesTarget",
      "jsRequiredElementFor_user_teammatesTarget",
      "jsRequiredHiddenClass",
    );

    useSidePanelContent(this);
  }

  clickOutside() {
    this.dispatch("click:outside");
  }
}
