import { Controller } from "@hotwired/stimulus";
import { trackEventWithDataToAmplitudeAndGoogle } from "../../analytics/global_analytics";
import { request } from "../../utils/http/client";
import { HTTP_METHODS } from "../../utils/http/constants";

export default class WorkInteractionDetailExecutiveSummaryController extends Controller {
  static targets = [
    "dialogWrapper",
    "dialogText",
    "dialogOverlay",
    "buttonCopy",
    "buttonRegenerate",
  ];

  static values = {
    workInteractionId: String,
  };

  async generateFollowupEmail(evt) {
    evt.preventDefault();
    this.buttonRegenerateTarget.disabled = true;
    trackEventWithDataToAmplitudeAndGoogle(
      "followupEmail",
      "buttonClick",
      "Button Click - Followup Email",
    );

    this.dialogWrapperTarget.classList.remove("closed");
    this.dialogOverlayTarget.classList.remove("closed");
    this.dialogTextTarget.innerHTML =
      "<p>Please wait. Generating follow-up email ...</p>";

    const POST_URL = `/api/v2/work-interactions/${this.workInteractionIdValue}/followup-email`;
    try {
      const req = await request(HTTP_METHODS.GET, POST_URL);
      let generatedEmail = "";
      for (let i = 0; i < req.length; i += 1) {
        const text = String(req[i]).trim();
        if (text.startsWith("-")) {
          generatedEmail += `<li>${text.substring(2)}</li>`;
        } else {
          generatedEmail += `<p>${text}</p>`;
        }
      }
      this.dialogTextTarget.innerHTML = generatedEmail;
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
      if (
        error.response.includes("Too many words in original text.") ||
        error.response.includes("Too few words in original text.")
      ) {
        this.dialogTextTarget.innerHTML = `<div class="infotext error-text"><p>${error.response.replace(
          /['"]+/g,
          "",
        )}</p></div>`;
      } else {
        this.dialogTextTarget.innerHTML =
          '<div class="infotext error-text"><p>Sorry, an error occurred. Please try again.</p></div>';
      }
    }
    this.buttonRegenerateTarget.disabled = false;
    return false;
  }

  copyGeneratedFollowupEmail(evt) {
    evt.preventDefault();
    trackEventWithDataToAmplitudeAndGoogle(
      "followupEmailCopyClipboard",
      "buttonClick",
      "Button Click - Followup Email Copy Clipboard",
    );
    navigator.clipboard.write([
      new ClipboardItem({
        "text/plain": new Blob([this.dialogTextTarget.innerText], {
          type: "text/plain",
        }),
        "text/html": new Blob([this.dialogTextTarget.innerHTML], {
          type: "text/html",
        }),
      }),
    ]);
    // Provide visual feedback that copy button has been clicked
    const buttonCopyInnerHTML = this.buttonCopyTarget.innerHTML;
    this.buttonCopyTarget.innerHTML = "Text copied!";
    setInterval(() => {
      this.buttonCopyTarget.innerHTML = buttonCopyInnerHTML;
    }, 1500);
    return false;
  }
}
