/* eslint import/prefer-default-export: "off" */

/**
 * Allow a controller to use a dialog.
 * - The controller must define the `dialogWrapper` and `dialogOverlay` targets
 * - The controller can eventually define a `shouldBypassDialog` method that
 *   can return true if the dialog should not be opened.
 */
export const useDialog = (controller) => {
  Object.assign(controller, {
    openDialog(evt) {
      evt.preventDefault();

      if (this.shouldBypassDialog && this.shouldBypassDialog(evt)) {
        this.dispatch("bypassDialog", {});
        return;
      }

      const dialogOverlayClosedClass =
        this.dialogWrapperTarget.dataset.closedClass;
      const dialogWrapperClosedClass =
        this.dialogWrapperTarget.dataset.closedClass;
      this.dialogOverlayTarget.classList.remove(dialogOverlayClosedClass);
      this.dialogWrapperTarget.classList.remove(dialogWrapperClosedClass);

      this.dispatch("dialogOpened");
    },

    closeDialog(evt) {
      // If Turbo and stimulus are active we don't need the navigation in this
      // case as we are staying on the same page.
      // We need to let the form being submitted or the navigation in the current
      // frame to continue if the turbo frame is set on the target.
      if (
        window.Turbo &&
        evt.target?.type !== "submit" &&
        evt.target?.dataset.turboFrame === undefined
      ) {
        evt.preventDefault();
      }

      const dialogOverlayClosedClass =
        this.dialogWrapperTarget.dataset.closedClass;
      const dialogWrapperClosedClass =
        this.dialogWrapperTarget.dataset.closedClass;
      this.dialogWrapperTarget.classList.add(dialogWrapperClosedClass);
      this.dialogOverlayTarget.classList.add(dialogOverlayClosedClass);

      this.dispatch("dialogClosed");
    },
  });
};
