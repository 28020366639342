import { Controller } from "@hotwired/stimulus";
import { useDropdown } from "../../behaviors";

export default class WorkInteractionUpdateFormController extends Controller {
  static targets = ["work_streamFieldElement", "formElement"];

  static classes = ["errorFieldWrapper"];

  firstTime = true; // FIXME: This is a hack as useDropdown triggers the change event on connect

  connect() {
    useDropdown(this, this.work_streamFieldElementTarget, {
      useAbsolutePosition: true,
      smallButton: true,
    });
  }

  onChange() {
    if (this.firstTime) {
      this.firstTime = false;
      return;
    }
    this.formElementTarget.submit();
  }
}
